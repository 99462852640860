import React, { useEffect, useState, useMemo } from 'react'
import {} from '../../components/Container'
import { useSelector, useDispatch } from 'react-redux'
import { ROUTES } from '../../config/routes'
import { useNavigate, useLocation } from 'react-router-dom'
import { useStyles } from './styles'
import {
  Loader,
  Button,
  Container,
  Section,
  SectionFixed,
  useConfirmation,
  MenuButton,
  TextInput
} from '../../components'
import ListFilter from '../../sections/ListFilter'
import InfiniteScroll from 'react-infinite-scroll-component'
import {
  Box,
  ListItemText,
  ListItemIcon,
  Tooltip,
  Skeleton,
  SvgIcon,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  ToggleButtonGroup,
  ToggleButton,
  DialogActions
} from '@mui/material'
import { ReactComponent as SortDown } from '../../assets/svg/SortDown.svg'
import { ReactComponent as SortUp } from '../../assets/svg/SortUp.svg'
import { getWorkspaces, updateWorkspace } from '../../store/Workspace/Actions'
import { HideColumn, ResizableCell } from '../../components/TableActions'
import ReactResizeDetector from 'react-resize-detector'
import { checkUserRoleViewer } from '../../utils/User'
import clsx from 'clsx'
import EmptyRecord from '../../sections/EmptyRecords'
import moment from 'moment'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import { v4 as uuid } from 'uuid'
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline'
import trackEvent from '../../utils/TrackEvent/TrackEvent'
import mixpanelEvents from '../../config/mixpanelEvents'

const getConfigFromLocal = () => {
  let data = localStorage.getItem('workspaceConfigMap')
  data = data ? JSON.parse(data) : null
  return data
}

const WorkspaceList = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { WORKSPACE } = ROUTES
  const config = getConfigFromLocal()
  const { ConfirmDialog, showConfirmDialog } = useConfirmation()
  const isUserViewer = checkUserRoleViewer()

  const {
    columnWidths: configColumnWidths,
    hiddenColumns: configHiddenColumns,
    sortOrder: configSortOrder
  } = config || {}

  const workspaceState = useSelector((state) => state.workspace)
  const {
    workspaces,
    hasNextPage,
    searchKeyword: searchKeywordState
  } = workspaceState
  const [keyword, setKeyword] = useState('')

  const [keywordState, setKeywordState] = useState('')
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(true)
  const [tableLoading, setTableLoading] = useState(false)
  const [fetchingData, setFetchingData] = useState(false)
  const [sortOrder, setSortOrder] = useState(
    configSortOrder || {
      created_at: 'desc'
    }
  )
  const [searchControllers, setSearchControllers] = useState({})
  const scrollControlDiv = 'scrollableWorkspaceDiv'
  const [columnWidths, setColumnWidths] = useState(configColumnWidths || {})
  const [columns, setColumns] = useState([
    'workspace_name',
    'created_by_user',
    'created_at',
    'updated_at'
  ])
  const [hiddenColumns, setHiddenColumns] = useState(configHiddenColumns || [])
  const [visibleColumns, setVisibleColumns] = useState([
    'workspace_name',
    'created_by_user',
    'created_at',
    'updated_at'
  ])
  const [isResizing, setIsResizing] = useState(false)
  const [isHovering, setIsHovering] = useState(false)
  const [isMenuHovering, setIsMenuHovering] = useState(false)

  const visibleColumnPercentageTotal = useMemo(() => {
    let total = 0
    ;[...visibleColumns, 'menu_button'].forEach((column) => {
      total += columnWidths[column]
    })
    return total
  }, [columnWidths, visibleColumns])

  const [openEditDialog, setOpenEditDialog] = useState(false)
  const [workspaceData, setWorkspaceData] = useState({
    name: '',
    description: ''
  })
  const currentUserName = useSelector(
    (state) => state.authenticate.user?.domain?.user_name || ''
  )

  useEffect(() => {
    if (searchKeywordState) {
      setKeyword(searchKeywordState)
      setKeywordState(searchKeywordState)
    }
  }, [searchKeywordState])

  const clearSearch = () => {
    setKeyword('')
    setKeywordState('')
    setPage(1)
  }

  const checkHorizontalScrollbar = (target) => {
    const element = document.getElementById(target)
    return element ? element.scrollHeight !== element.clientHeight : false
  }

  useEffect(() => {
    if (workspaces) {
      setLoading(false)
      const hasScroll = checkHorizontalScrollbar(scrollControlDiv)
      if (!hasScroll && hasNextPage && !fetchingData) {
        navigateToPage()
      }
    } else {
      setLoading(true)
    }
  }, [workspaces, fetchingData])

  useEffect(() => {
    if (!workspaces) {
      fetchData()
    }
  }, [])

  const clearController = () => {
    Object.keys(searchControllers).forEach((key) => {
      const cancelRequest = searchControllers[key]
      cancelRequest.abort()
    })
    setSearchControllers({})
  }

  const initColumnsWidth = (visibleColumns) => {
    if (visibleColumns.length === 0) return
    const newWidths = columnWidths
    visibleColumns = [...visibleColumns, 'menu_button']
    visibleColumns.forEach((item) => {
      if (!Object.keys(columnWidths).includes(item)) {
        if (item === 'workspace_name') {
          newWidths[item] = 25
        } else if (item === 'menu_button') {
          newWidths[item] = 5
        } else {
          newWidths[item] = 10
        }
      }
    })
    if (!_.isEmpty(newWidths)) setColumnWidths(newWidths)
  }

  const saveConfigToLocal = () => {
    const dataMap = {
      columnWidths,
      hiddenColumns,
      sortOrder
    }
    localStorage.setItem('knowledgeConfigMap', JSON.stringify(dataMap))
  }

  useEffect(() => {
    saveConfigToLocal()
  }, [columnWidths, hiddenColumns, sortOrder])

  useEffect(() => {
    const visibleColumns = columns.filter(
      (item) => !hiddenColumns.includes(item)
    )
    setVisibleColumns(visibleColumns)
    initColumnsWidth(visibleColumns)
  }, [hiddenColumns, columns])

  const fetchData = (
    page = 1,
    search = keyword,
    currentSortOrder = sortOrder
  ) => {
    setFetchingData(true)
    if (page === 1) {
      setTableLoading(true)
      clearController()
    }
    const newController = new AbortController()
    setSearchControllers((prevControllers) => ({
      ...prevControllers,
      [page]: newController
    }))
    const [sortBy] = Object.keys(currentSortOrder)
    const sortOrder = currentSortOrder[sortBy]

    const data = {
      page_num: page,
      page_size: 30,
      sort_by: sortBy,
      sort_order: sortOrder,
      keyword: search
    }

    const callback = () => {
      setTableLoading(false)
      setFetchingData(false)
    }
    dispatch(getWorkspaces(data, callback, { signal: newController.signal }))
  }

  const navigateToPage = () => {
    fetchData(page + 1)
    setPage(page + 1)
  }

  const searchOnEnter = (event) => {
    if (event?.keyCode === 13) {
      setPage(1)
      setKeywordState(keyword)
      fetchData(1, keyword)
    }
  }

  const handleSort = (column, order) => {
    const newOrder = _.cloneDeep(sortOrder)
    if (newOrder?.[column] && newOrder[column] === order) {
      delete newOrder[column]
    } else {
      newOrder[column] = order
    }
    setSortOrder(newOrder)
    setPage(1)
    fetchData(1, keyword, newOrder)
  }

  const TableLoader = ({ count, hideHeader = false }) => {
    return (
      <table
        className={classes.tableWrapper}
        style={{ width: visibleColumnPercentageTotal + '%' }}
      >
        {!hideHeader && (
          <thead>
            <tr>
              {visibleColumns.map((column, index) => {
                const width = getColWidth(columnWidths[column], column)
                return (
                  <th
                    key={index}
                    style={{ width }}
                    className={classes.tableHeaderLoader}
                  >
                    <Skeleton
                      variant="rectangular"
                      key={index}
                      height={25}
                      sx={{ marginBottom: '5px' }}
                    />
                  </th>
                )
              })}
            </tr>
          </thead>
        )}
        <tbody>
          {[...Array(count)].map((item, index) => (
            <tr key={index}>
              {visibleColumns.map((column, index) => {
                const width = getColWidth(columnWidths[column], column)
                return (
                  <td
                    key={index}
                    className={classes.tableRowLoader}
                    style={{ width }}
                  >
                    <Skeleton
                      variant="rectangular"
                      key={index}
                      width={'100%'}
                      height={25}
                      sx={{ marginBottom: '5px' }}
                    />
                  </td>
                )
              })}
            </tr>
          ))}
        </tbody>
      </table>
    )
  }

  const GetSortIcon = ({ id, isSelected, order }) => {
    return (
      <Box className={classes.sortLabel}>
        <IconButton
          disableRipple
          disabled={tableLoading}
          onClick={() => {
            handleSort(id, 'asc')
          }}
        >
          <SvgIcon
            className={
              isSelected && order === 'asc'
                ? classes.sortIconActive
                : classes.sortIcon
            }
          >
            <SortUp />
          </SvgIcon>
        </IconButton>
        <IconButton
          disableRipple
          disabled={tableLoading}
          onClick={() => {
            handleSort(id, 'desc')
          }}
        >
          <SvgIcon
            className={
              isSelected && order === 'desc'
                ? classes.sortIconActive
                : classes.sortIcon
            }
          >
            <SortDown />
          </SvgIcon>
        </IconButton>
      </Box>
    )
  }

  const handleResize = (column, width) => {
    if (width > 10) {
      setColumnWidths({
        ...columnWidths,
        [column]: width
      })
    }
  }

  const resetView = () => {
    setHiddenColumns([])
    setColumnWidths({})
    const defaultSortOrder = {
      created_at: 'desc'
    }
    setSortOrder(defaultSortOrder)
    setPage(1)
    fetchData(1, keyword, defaultSortOrder)
  }

  const handleMenuOpen = (row) => {
    const { id } = row || {}
    if (id) handleOpenWorkspace(id, true)
  }

  const handleMenuEdit = (row) => {
    const { id, workspace_name, description } = row || {}
    setWorkspaceData({
      name: workspace_name,
      description,
      id
    })
    setOpenEditDialog('edit')
  }

  const handleMenuDelete = (row) => {
    const { id, workspace_name } = row || {}
    showConfirmDialog({
      confirmationMessageTitle: (
        <div>
          Are you sure you want to delete workspace <b>{workspace_name}</b> ?
        </div>
      ),
      confirmationMessage: (
        <div>
          This action will also delete all the drafts associated with this
          workspace
        </div>
      ),
      onConfirm: () => {
        const request = {
          id,
          mode: 'delete'
        }
        dispatch(updateWorkspace(request))
      }
    })
  }

  const menuOptions = [
    {
      label: 'Open',
      icon: <OpenInNewIcon />,
      onClick: handleMenuOpen
    },
    {
      label: 'Edit',
      icon: <DriveFileRenameOutlineIcon />,
      onClick: handleMenuEdit
    },
    {
      label: 'Delete',
      icon: <DeleteIcon />,
      onClick: handleMenuDelete
    }
  ]

  const getColWidth = (width, key) => {
    if (width) {
      return width + '%'
    } else return 'auto'
  }

  const formatHeader = (header) => {
    return _.startCase(header)
  }

  const handleOpenWorkspace = (id, newTab) => {
    trackEvent(mixpanelEvents.WORKSPACE_OPENED, 'SUCCESS', {}, {})

    if (newTab) {
      window.open(`${WORKSPACE}/${id}`, '_blank')
    } else {
      navigate(`${WORKSPACE}/${id}`)
    }
  }

  const renderKeyValues = (key, value, isRecent) => {
    if (key === 'created_at' || key === 'updated_at')
      return moment(value).format('MMM D, YYYY')
    else if (key === 'workspace_name') {
      return isRecent ? (
        <div>
          {value}
          <div>(Recently Added)</div>
        </div>
      ) : (
        value
      )
    } else return value
  }

  const handleMenuItems = (option, classes) => {
    return (
      <Box sx={{ display: 'flex' }}>
        <ListItemIcon className={classes.icon}>{option.icon}</ListItemIcon>
        <ListItemText className={classes.tableRowText}>
          {option.name}
        </ListItemText>
      </Box>
    )
  }

  const handleEditButton = () => {
    if (openEditDialog === 'create') {
      trackEvent(mixpanelEvents.WORKSPACE_CREATED, 'SUCCESS', {}, {})
      const id = uuid()
      const request = {
        id,
        workspace_name: workspaceData.name,
        description: workspaceData.description,
        created_by_user: currentUserName,
        mode: 'create'
      }
      dispatch(updateWorkspace(request))
    } else {
      if (workspaceData.id) {
        const request = {
          id: workspaceData.id,
          workspace_name: workspaceData.name,
          description: workspaceData.description,
          created_by_user: currentUserName,
          mode: 'update'
        }
        dispatch(updateWorkspace(request))
      }
    }
    handleEditClose()
  }

  const handleEditClose = () => {
    setOpenEditDialog(false)
    setWorkspaceData({
      name: '',
      description: ''
    })
  }

  const renderEditDialog = () => {
    return (
      <Dialog
        fullWidth
        maxWidth="sm"
        open={!!openEditDialog}
        onClose={handleEditClose}
      >
        <DialogTitle>{_.startCase(openEditDialog)} Workspace</DialogTitle>
        <DialogContent>
          <TextInput
            placeholder="Name"
            value={workspaceData?.name || ''}
            handleChange={(e) =>
              setWorkspaceData({
                ...workspaceData,
                name: e.target.value
              })
            }
          />
          <TextInput
            style={{ marginTop: '10px' }}
            placeholder="Description"
            value={workspaceData?.description || ''}
            multiLine
            rows={4}
            handleChange={(e) => {
              setWorkspaceData({
                ...workspaceData,
                description: e.target.value
              })
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditButton}>Save</Button>
          <Button variant="outlined" onClick={handleEditClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  return loading ? (
    <Loader loading={loading} caption="loading your workspace" flex />
  ) : (
    <Container>
      <SectionFixed>
        <ListFilter
          disabled={tableLoading}
          isShowFilter={false}
          filterData={[]}
          filter={() => {}}
          refresh={false}
          searchValue={keyword}
          handleSearch={setKeyword}
          searchOnEnter={searchOnEnter}
          selectedFilter={[]}
          setSelectedFilter={() => {}}
          pageType="workspace"
          isEditable={!isUserViewer}
          handleCreateNewDoc={() => setOpenEditDialog('create')}
          afterSearchElement={
            <Box className={classes.searchButtonWrapper}>
              <ToggleButtonGroup
                onClick={() => resetView()}
                sx={{ height: '28px', marginLeft: '5px' }}
              >
                <Tooltip title="Reset">
                  <ToggleButton value="grid" aria-label="left aligned">
                    <RestartAltIcon
                      sx={{ padding: '1px 6px', fontSize: '18px' }}
                    />
                  </ToggleButton>
                </Tooltip>
              </ToggleButtonGroup>
              <HideColumn
                visibleColumns={visibleColumns}
                disabled={tableLoading}
                columns={columns}
                setColumns={setColumns}
                hiddenColumns={hiddenColumns}
                setHiddenColumns={setHiddenColumns}
                defaultHidden={[]}
                viewMode="table"
                formatHeader={formatHeader}
              />
            </Box>
          }
        />
      </SectionFixed>
      <Section overFlow>
        <Box
          id={scrollControlDiv}
          sx={{ height: '100%', width: '100%', overflow: 'auto' }}
        >
          {tableLoading ? (
            <TableLoader count={30} />
          ) : (
            <InfiniteScroll
              dataLength={workspaces?.length ? workspaces.length : 0}
              next={() => navigateToPage()}
              hasMore={hasNextPage}
              loader={fetchingData && <TableLoader count={10} hideHeader />}
              scrollableTarget={scrollControlDiv}
            >
              <Container>
                <Section overFlow>
                  <table
                    className={classes.tableWrapper}
                    style={{ width: visibleColumnPercentageTotal + '%' }}
                  >
                    <ReactResizeDetector handleWidth>
                      {({ width }) => (
                        <>
                          <colgroup>
                            {[...visibleColumns, 'menu_button'].map(
                              (column) => {
                                const resizeWidth = getColWidth(
                                  columnWidths[column],
                                  column
                                )
                                return (
                                  <col
                                    key={column}
                                    style={{ width: resizeWidth }}
                                  />
                                )
                              }
                            )}
                          </colgroup>
                          <thead>
                            <tr>
                              {visibleColumns.map((key) => (
                                <ResizableCell
                                  parentWidth={width}
                                  key={key}
                                  onResize={(width) => handleResize(key, width)}
                                  width={getColWidth(columnWidths[key], key)}
                                  id={key}
                                  isResizing={isResizing}
                                  setIsResizing={setIsResizing}
                                  isHovering={isHovering}
                                  setIsHovering={setIsHovering}
                                  tableComponent="header"
                                >
                                  <Box className={classes.tableHeaderText}>
                                    <Box>{key && formatHeader(key || '')}</Box>
                                    <GetSortIcon
                                      id={key}
                                      isSelected={Object.keys(
                                        sortOrder
                                      ).includes(key)}
                                      order={sortOrder[key]}
                                    />
                                  </Box>
                                </ResizableCell>
                              ))}
                              <th
                                className={clsx(
                                  classes.tableHeader,
                                  classes.menuButton
                                )}
                              >
                                <Box className={classes.tableHeaderText}>
                                  <Box> &nbsp;</Box>
                                </Box>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {_.isArray(workspaces) && (
                              <>
                                {workspaces.map((el, index) => {
                                  const { id, status = false, isRecent } = el
                                  const rowLoading = !!status
                                  return (
                                    <>
                                      <tr
                                        key={index}
                                        onDoubleClick={(e) => {
                                          if (!rowLoading) {
                                            handleOpenWorkspace(id)
                                          }
                                        }}
                                        style={{
                                          background: rowLoading
                                            ? 'inherit'
                                            : '',
                                          opacity: rowLoading ? '0.38' : 1,
                                          cursor: rowLoading
                                            ? 'inherit'
                                            : 'pointer'
                                        }}
                                      >
                                        {visibleColumns.map((key) => {
                                          return (
                                            <ResizableCell
                                              key={key}
                                              parentWidth={width}
                                              onResize={(width) =>
                                                handleResize(key, width)
                                              }
                                              width={getColWidth(
                                                columnWidths[key],
                                                key
                                              )}
                                              id={key}
                                              isResizing={isResizing}
                                              setIsResizing={setIsResizing}
                                              tableComponent="row"
                                              isHovering={isHovering}
                                              isMenuHovering={
                                                isMenuHovering === id
                                              }
                                              setIsMenuHovering={
                                                setIsMenuHovering
                                              }
                                              setIsHovering={setIsHovering}
                                            >
                                              <Box
                                                className={classes.tableRowText}
                                              >
                                                {renderKeyValues(
                                                  key,
                                                  el[key],
                                                  isRecent
                                                )}
                                              </Box>
                                            </ResizableCell>
                                          )
                                        })}
                                        <td
                                          className={clsx(
                                            classes.tableRow,
                                            classes.menuButton
                                          )}
                                          onMouseEnter={() => {
                                            setIsHovering(id)
                                            setIsMenuHovering(false)
                                          }}
                                          onMouseLeave={() => {
                                            setIsHovering(false)
                                            setIsMenuHovering(false)
                                          }}
                                        >
                                          <MenuButton
                                            disabled={rowLoading}
                                            options={menuOptions}
                                            callBackData={el}
                                            menuOpenCallback={() => {
                                              setIsMenuHovering(id)
                                            }}
                                            menuCloseCallback={() => {
                                              setIsMenuHovering(false)
                                            }}
                                          />
                                        </td>
                                      </tr>
                                    </>
                                  )
                                })}
                                {workspaces?.length === 0 &&
                                  !fetchingData &&
                                  !tableLoading && (
                                    <tr className={classes.tableRow}>
                                      <td
                                        colSpan={visibleColumns.length + 1}
                                        style={{ cursor: 'default' }}
                                      >
                                        <Box
                                          className={classes.tableRowText}
                                          sx={{ padding: '15px' }}
                                        >
                                          <EmptyRecord
                                            displayMessage={
                                              'No workspace found'
                                            }
                                          />
                                        </Box>
                                      </td>
                                    </tr>
                                  )}
                              </>
                            )}
                          </tbody>
                        </>
                      )}
                    </ReactResizeDetector>
                  </table>
                </Section>
              </Container>
            </InfiniteScroll>
          )}
        </Box>
      </Section>
      {renderEditDialog()}
      {ConfirmDialog}
    </Container>
  )
}

export default WorkspaceList
