import { useState, useEffect } from 'react'
import {
  Box,
  Grid,
  ListItem,
  List,
  ListItemText,
  IconButton,
  ListItemButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Dialog,
  TextField,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
  Divider
} from '@mui/material'
import { useStyles } from './styles'
import _ from 'lodash'
import { getTags, updateKey } from '../../store/TagCenter/Actions'
import { useSelector, useDispatch } from 'react-redux'
import {
  Loader,
  Button,
  MenuButton,
  TextInput,
  useConfirmation,
  ReactSelect
} from '../../components'
import { Container, Section, SectionFixed } from '../../components/Container'
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined'
import clsx from 'clsx'
import DoneIcon from '@mui/icons-material/Done'
import DeleteIcon from '@mui/icons-material/Delete'
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline'
import { v4 as uuid } from 'uuid'
import { toast } from 'react-toastify'
import ChecklistIcon from '@mui/icons-material/Checklist'
import SortTable from './SortTable'
import LockIcon from '@mui/icons-material/Lock'
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'
import DateRangeIcon from '@mui/icons-material/DateRange'
import TodayIcon from '@mui/icons-material/Today'
import PeopleIcon from '@mui/icons-material/People'
import DescriptionIcon from '@mui/icons-material/Description'
import ConstructionIcon from '@mui/icons-material/Construction'
import BookmarkIcon from '@mui/icons-material/Bookmark'
import HttpIcon from '@mui/icons-material/Http'
import {
  checkUserRoleAdmin,
  checkUserRoleOwner,
  hasAccess
} from '../../utils/User'
import Checkbox from '@mui/material/Checkbox'
import { DOMAIN_CONFIG_UPDATE } from '../../store/actionTypes'
import { getTagCenterSettings, putUserDomain } from '../../store/api'

export const getTagCenterIcon = (type, editable) => {
  switch (editable) {
    case false:
      return <LockIcon />
    case 'default':
      return <BookmarkIcon />
    default:
      switch (type) {
        case 'multiselect':
          return <ChecklistIcon />
        case 'singleselect':
          return <DoneIcon />
        case 'multidate':
          return <DateRangeIcon />
        case 'singledate':
          return <TodayIcon />
        case 'multiurl':
          return <HttpIcon />
        case 'singleurl':
          return <HttpIcon />
      }
  }
}
export const getVisibiltyIcon = (type) => {
  const icons = []
  if (type?.includes('document')) {
    icons.push(
      <Tooltip title="Documents" placement="top">
        <DescriptionIcon />
      </Tooltip>
    )
  }
  if (type?.includes('people')) {
    icons.push(
      <Tooltip title="People" placement="top">
        <PeopleIcon />
      </Tooltip>
    )
  }
  if (type?.includes('project')) {
    icons.push(
      <Tooltip title="Projects" placement="top">
        <ConstructionIcon />
      </Tooltip>
    )
  }
  return icons
}

const Search = (props) => {
  const { value = '' } = props

  const [showSearch, setShowSearch] = useState(false)
  const classes = useStyles()
  return (
    <Box className={classes.searchTextWrapper}>
      {showSearch ? (
        <TextField
          variant="standard"
          placeholder="Search"
          onClick={(e) => e.stopPropagation()}
          InputProps={{
            startAdornment: (
              <IconButton
                disableRipple
                onClick={(e) => {
                  e.stopPropagation()
                  setShowSearch(false)
                }}
              >
                <SearchIcon />
              </IconButton>
            )
          }}
          {...props}
        />
      ) : value ? (
        <Box className={classes.searchTextClose}>
          <IconButton
            disableRipple
            onClick={(e) => {
              e.stopPropagation()
              setShowSearch(true)
            }}
          >
            <SearchIcon />
          </IconButton>
          {value}
          <IconButton
            disableRipple
            onClick={(e) => {
              e.stopPropagation()
              props.onChange({
                target: {
                  value: ''
                }
              })
            }}
          >
            <ClearIcon />
          </IconButton>
        </Box>
      ) : (
        <IconButton
          disableRipple
          onClick={(e) => {
            e.stopPropagation()
            setShowSearch(true)
          }}
        >
          <SearchIcon />
        </IconButton>
      )}
    </Box>
  )
}

const TagCenterSettings = ({ isFocused }) => {
  const [loading, setLoading] = useState(true)
  const [tags, setTags] = useState({})
  const [mainTags, setMainTags] = useState({})
  const [searchText, setSearchText] = useState({})
  const classes = useStyles()
  const dispatch = useDispatch()
  const [tagsCenterStateTags, setTagsCenterStateTags] = useState(null)

  const [selectedKey, setSelectedKey] = useState({
    id: 'all',
    type: 'default'
  })
  const [newKey, setNewKey] = useState({
    key: '',
    value: '',
    type: 'multiselect'
  })

  const [showEdit, setShowEdit] = useState(false)
  const [editType, setEditType] = useState('new')
  const [keyCopy, setKeyCopy] = useState('')
  const [expanded, setExpanded] = useState({})
  const [selectedRows, setSelectedRows] = useState({})
  const [showDocumentType, setShowDocumentType] = useState(false)
  const isAdmin = checkUserRoleAdmin()
  const isOwner = checkUserRoleOwner()
  const auth = useSelector((state) => state.authenticate)
  const domain = auth?.user?.domain
  const { domain_id, domain_config } = domain || {}
  const [accessControl, setAccessControl] = useState(null)
  const canCreate = hasAccess('tags', 'create')
  const canEdit = hasAccess('tags', 'edit')
  const canDelete = hasAccess('tags', 'delete')

  useEffect(() => {
    if (domain_config && accessControl === null) {
      setAccessControl({ ...domain_config })
    }
  }, [domain_config])

  const handleAccordianChange = (key) => {
    const isExpanded = expanded[key]
    setExpanded({
      ...expanded,
      [key]: !isExpanded
    })
  }

  const { ConfirmDialog, showConfirmDialog } = useConfirmation()

  useEffect(() => {
    if (isFocused) {
      if (tagsCenterStateTags) {
        setMainTags(tagsCenterStateTags)
        const values = Object.entries(tagsCenterStateTags || {})
          .map(([key, item]) => {
            if (item.value_type?.includes('document_type')) {
              return { key, ...item }
            }
            return null
          })
          .filter((item) => item !== null)
        if (values.length > 0) {
          setShowDocumentType(values[0]?.key)
        }
        setLoading(false)
      }
      if (
        selectedKey?.type === 'tag' &&
        !Object.keys(tagsCenterStateTags).includes(selectedKey?.id)
      ) {
        setSelectedKey({
          id: 'all',
          type: 'default'
        })
      }
    }
  }, [tagsCenterStateTags, isFocused])

  useEffect(() => {
    if (isFocused) {
      if (!tagsCenterStateTags) {
        setLoading(true)
        setExpanded({})
        setSelectedKey({
          id: 'all',
          type: 'default'
        })
        fetchTags()
      }
    }
  }, [isFocused])

  const fetchTags = async () => {
    setLoading(true)
    const res = await getTagCenterSettings()
    if (res.status === 200) {
      const { data } = res
      const formatedData = {}
      data.forEach((element) => {
        if (formatedData[element.key]?.data) {
          formatedData[element.key].data.push(element)
        } else {
          formatedData[element.key] = {
            data: [element],
            type: element.type,
            usage_count: element?.usage_count,
            value_type: element?.value_type,
            key_type: element?.key_type,
            editable: element?.editable !== false,
            id: element.id,
            value: element.value
          }
        }
      })

      setTagsCenterStateTags(formatedData)
    } else {
      toast.error('Failed to fetch tags')
    }
    setLoading(false)
  }

  const getMenuOptions = (editable) => {
    if (editable) {
      return [
        {
          label: 'Edit',
          icon: <DriveFileRenameOutlineIcon />,
          disabled: !canEdit,
          onClick: (key) => openDialog(key, 'edit')
        },
        {
          label: 'Delete',
          icon: <DeleteIcon />,
          disabled: !canDelete,
          onClick: (key) => {
            const req = {
              key,
              status: 'delete',
              synced: false,
              type: tags[key].type
            }
            showConfirmDialog({
              onConfirm: () => {
                dispatch(updateKey(req))
              },
              confirmationMessageTitle: `Are you sure you want to delete this key "${key}" ?`
            })
          }
        }
      ]
    } else {
      return [
        {
          label: 'Edit',
          icon: <DriveFileRenameOutlineIcon />,
          disabled: !canEdit,
          onClick: (key) => openDialog(key, 'edit')
        }
      ]
    }
  }

  const openDialog = (key, method) => {
    setShowEdit(true)
    setEditType(method)
    if (key) {
      setNewKey({
        key,
        value: tags[key].value,
        type: tags[key].type,
        prevType: tags[key].type,
        editable: tags[key].editable,
        key_type: tags[key]?.key_type
      })
      setKeyCopy(key)
    } else {
      setNewKey({
        key: '',
        value: '',
        type: 'multiselect'
      })
    }
  }

  const closeDialog = () => {
    setNewKey({
      key: '',
      value: '',
      type: 'multiselect'
    })
    setShowEdit(false)
  }

  const handleAddNewKey = () => {
    const newKeyCopy = _.cloneDeep(newKey)
    if (Object.keys(tagsCenterStateTags).includes(newKeyCopy.key)) {
      toast.error('Tag with the same key already exists')
      return
    } else if (!newKeyCopy.key) {
      toast.error('Tag key is required')
      return
    } else if (newKeyCopy.key.includes('_')) {
      toast.error('Tag key cannot contain underscore')
      return
    }
    const id = uuid()
    const req = {
      id,
      key: newKeyCopy.key.toString(),
      value: '',
      status: 'create',
      key_type: newKeyCopy.key_type,
      editable: true,
      type: newKeyCopy.type,
      synced: false
    }
    dispatch(updateKey(req))
    closeDialog()
  }

  const warnHandleEditKey = () => {
    closeDialog()
    if (newKey?.prevType && newKey?.type !== newKey?.prevType) {
      showConfirmDialog({
        onConfirm: () => {
          handleEditKey()
        },
        confirmationMessageTitle:
          'Are you sure you want to update the key type?',
        confirmationMessage:
          'This action will remove tags under this key and cannot be undone.'
      })
    } else {
      handleEditKey()
    }
  }

  const handleEditKey = () => {
    const newKeyCopy = _.cloneDeep(newKey)
    if (
      Object.keys(tags).includes(newKeyCopy.key) &&
      newKeyCopy.key !== keyCopy
    ) {
      toast.error('Tag with the same key already exists')
      return
    }
    if (!newKeyCopy.key) {
      toast.error('Tag key is required')
      return
    }
    const req = {
      key: newKeyCopy.key.toString(),
      oldKey: keyCopy,
      value: '',
      status: 'update',
      key_type: newKeyCopy.key_type,
      type: newKeyCopy.type,
      synced: false
    }
    dispatch(updateKey(req))
  }

  const handleKeySelect = (key) => {
    if (key?.type === 'tag') {
      setExpanded({
        [key.id]: true
      })
    } else {
      setExpanded({})
    }
    setSelectedKey(key)
  }

  const handleDialogEdit = (e, key) => {
    setNewKey({
      ...newKey,
      [key]: e.target.value
    })
  }
  const typeOptions = [
    { value: 'multiselect', label: 'Multi-Select Text' },
    { value: 'singleselect', label: 'Single-Select Text' },
    { value: 'multidate', label: 'Multi-Select Date' },
    { value: 'singledate', label: 'Single-Select Date' },
    { value: 'multiurl', label: 'Multi-Select URL' },
    { value: 'singleurl', label: 'Single-Select URL' }
  ]
  const keyTypeOptions = [
    { value: 'document', label: 'Document' },
    { value: 'people', label: 'People' },
    { value: 'project', label: 'Project' }
  ]
  const renderEditDialog = () => {
    const title = editType === 'new' ? 'Create Tag Key' : 'Edit Tag Key'
    const buttonText = editType === 'new' ? 'Create' : 'Save'
    const buttonFunction =
      editType === 'new' ? handleAddNewKey : warnHandleEditKey
    const keyTypeValue =
      editType === 'new'
        ? newKey?.key_type
          ? newKey?.key_type
          : ['document']
        : newKey?.key_type
    const { editable = true } = newKey
    return (
      <Dialog fullWidth maxWidth="xs" open={showEdit} onClose={closeDialog}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <TextInput
            sx={{ marginBottom: '15px' }}
            placeholder="Tag Key"
            value={newKey?.key}
            handleChange={(e) => handleDialogEdit(e, 'key')}
          />
          <Box sx={{ marginBottom: '15px' }}>
            <ReactSelect
              options={typeOptions}
              onChange={(e) => {
                setNewKey({
                  ...newKey,
                  type: e.value
                })
              }}
              defaultValue={typeOptions.find(
                (item) => item.value === newKey?.type
              )}
              placeholder="Tag Type"
            />
          </Box>
          <Box sx={{ marginBottom: '15px' }}>
            <ReactSelect
              options={keyTypeOptions}
              onChange={(e) => {
                setNewKey({
                  ...newKey,
                  key_type: e.map((item) => item.value)
                })
              }}
              isMulti
              isClearable={false}
              value={keyTypeValue?.map((item) => {
                return {
                  value: item,
                  label: _.startCase(item)
                }
              })}
              placeholder="Key Visibility"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={buttonFunction}>{buttonText}</Button>
          <Button variant="outlined" onClick={closeDialog}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const handleSearch = (value, key) => {
    setSearchText({
      ...searchText,
      [key]: value
    })
    if (value) {
      setExpanded({
        ...expanded,
        [key]: true
      })
    }
  }

  const filterTags = () => {
    if (!_.isEmpty(searchText)) {
      const newTags = _.cloneDeep(mainTags)
      Object.keys(newTags).forEach((key, index) => {
        const { data = [] } = newTags[key]
        const searchValue = searchText[key]
        const selected = selectedRows[key] || []
        if (searchValue) {
          newTags[key].data = data.filter(
            (item) =>
              item?.value?.toLowerCase()?.includes(searchValue.toLowerCase()) ||
              selected?.includes(item?.id)
          )
        }
      })
      setTags(newTags)
    } else {
      setTags(mainTags)
    }
  }

  useEffect(() => {
    filterTags()
  }, [searchText, mainTags, selectedRows])

  const handleSelectedRows = (rows, key) => {
    setSelectedRows({
      ...selectedRows,
      [key]: rows
    })
  }

  const handleAccessControl = async (type, role, value) => {
    setAccessControl((prevAccessControl) => {
      const newAccessControl = prevAccessControl ? { ...prevAccessControl } : {}
      newAccessControl[role] = newAccessControl[role]
        ? { ...newAccessControl[role] }
        : {}
      newAccessControl[role].tags = newAccessControl[role].tags
        ? { ...newAccessControl[role].tags }
        : {}
      newAccessControl[role].tags[type] = !value
      return newAccessControl
    })
  }

  useEffect(() => {
    const syncData = async () => {
      const req = {
        domain_id,
        domain_config: accessControl
      }
      const res = await putUserDomain(req)
      if (res.status === 200) {
        dispatch({
          type: DOMAIN_CONFIG_UPDATE,
          payload: { domain_config: accessControl }
        })
      } else {
        toast.error('Failed to update')
      }
    }
    if (accessControl && !_.isEqual(accessControl, domain_config)) {
      syncData()
    }
  }, [accessControl])

  return (
    <Container>
      {loading ? (
        <Loader loading={true} caption={'loading your tags'} flex />
      ) : (
        <Section overFlow>
          <Box className={classes.container}>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={3}
                lg={3}
                xl={2}
                className={classes.sidePane}
              >
                <Container>
                  <Section overFlow>
                    <Box className={classes.sidePaneContainer}>
                      <List className={classes.listWrapper}>
                        <ListItem disablePadding>
                          <ListItemButton
                            className={classes.listButtonWrapper}
                            selected={
                              selectedKey?.id === 'all' &&
                              selectedKey?.type === 'default'
                            }
                            onClick={() =>
                              handleKeySelect({
                                id: 'all',
                                type: 'default'
                              })
                            }
                          >
                            <ListItemText
                              primary={
                                <Box className={classes.listTextWrapper}>
                                  <Box className={classes.allText}>
                                    All Tags
                                  </Box>
                                </Box>
                              }
                            />
                          </ListItemButton>
                        </ListItem>
                        {showDocumentType && (
                          <ListItem disablePadding>
                            <ListItemButton
                              className={classes.listButtonWrapper}
                              selected={
                                selectedKey?.id === showDocumentType &&
                                selectedKey?.type === 'tag'
                              }
                              onClick={() =>
                                handleKeySelect({
                                  id: showDocumentType,
                                  type: 'tag',
                                  isDocumentType: true
                                })
                              }
                            >
                              <ListItemText
                                primary={
                                  <Box className={classes.listTextWrapper}>
                                    <Box className={classes.allText}>
                                      Document Type
                                    </Box>
                                  </Box>
                                }
                              />
                            </ListItemButton>
                          </ListItem>
                        )}
                        {isAdmin && (
                          <ListItem disablePadding>
                            <ListItemButton
                              className={classes.listButtonWrapper}
                              selected={
                                selectedKey?.id === 'settings' &&
                                selectedKey?.type === 'settings'
                              }
                              onClick={() =>
                                handleKeySelect({
                                  id: 'settings',
                                  type: 'settings'
                                })
                              }
                            >
                              <ListItemText
                                primary={
                                  <Box className={classes.listTextWrapper}>
                                    <Box className={classes.allText}>
                                      Settings
                                    </Box>
                                  </Box>
                                }
                              />
                            </ListItemButton>
                          </ListItem>
                        )}
                        <Box className={classes.allItem}>
                          <Divider />
                        </Box>
                        {Object.keys(tags).map((key, index) => {
                          const isSelected =
                            selectedKey?.id === key &&
                            selectedKey?.type === 'tag'
                          const {
                            synced = true,
                            type,
                            editable = true,
                            value_type,
                            key_type,
                            data
                          } = tags[key]
                          const length = data?.length - 1 || 0
                          const isDocumentType =
                            value_type?.includes('document_type')
                          return (
                            !isDocumentType && (
                              <ListItem disablePadding key={index}>
                                <ListItemButton
                                  disabled={!synced}
                                  className={classes.listButtonWrapper}
                                  selected={isSelected}
                                  onClick={() =>
                                    handleKeySelect({
                                      id: key,
                                      type: 'tag'
                                    })
                                  }
                                >
                                  <ListItemText>
                                    <Box className={classes.listTextWrapper}>
                                      <>
                                        {getTagCenterIcon(type, editable)}
                                        <Box className={classes.listText}>
                                          {key}
                                          {length > 0 && ` (${length})`}
                                        </Box>
                                      </>
                                      <Box className={classes.flexContainer}>
                                        <Box className={classes.visibilityIcon}>
                                          {getVisibiltyIcon(key_type).map(
                                            (icon) => icon
                                          )}
                                        </Box>
                                        <MenuButton
                                          options={getMenuOptions(editable)}
                                          callBackData={key}
                                        />
                                      </Box>
                                    </Box>
                                  </ListItemText>
                                </ListItemButton>
                              </ListItem>
                            )
                          )
                        })}
                        {canCreate && (
                          <ListItem disablePadding sx={{ marginTop: '10px' }}>
                            <ListItemButton
                              className={classes.listButtonWrapper}
                              onClick={() => openDialog(null, 'new')}
                            >
                              <ListItemText
                                primary={
                                  <Box
                                    className={clsx(
                                      classes.secondaryColor,
                                      classes.listTextWrapper
                                    )}
                                  >
                                    <AddBoxOutlinedIcon />
                                    <Box className={classes.listText}>
                                      Add New Tag
                                    </Box>
                                  </Box>
                                }
                              />
                            </ListItemButton>
                          </ListItem>
                        )}
                      </List>
                    </Box>
                  </Section>
                </Container>
              </Grid>
              <Grid item xs sm lg xl className={classes.mainPane}>
                {selectedKey?.isDocumentType ? (
                  <Box className={classes.mainPaneContainer}>
                    <Container>
                      <SectionFixed>
                        <Box className={classes.titleText}>
                          Document Type Tags
                        </Box>
                        <Box className={classes.subText}>
                          Manage your document types with ease. Add, edit, or
                          delete document types to streamline organization and
                          enhance your library and assets management experience.
                          Process your content intelligently with the Document
                          Type Tags.
                        </Box>
                      </SectionFixed>
                      <Section overFlow>
                        <Box className={classes.accordianWrapper}>
                          <Accordion
                            square
                            TransitionProps={{
                              timeout: { appear: 1, enter: 1, exit: 1 }
                            }}
                            defaultExpanded={true}
                            expanded={!!expanded[selectedKey?.id]}
                            onChange={() =>
                              handleAccordianChange(selectedKey?.id)
                            }
                          >
                            <AccordionSummary
                              expandIcon={
                                <Box className={classes.secondaryColor}>
                                  {expanded[selectedKey?.id]
                                    ? 'Collapse'
                                    : 'Expand'}
                                </Box>
                              }
                            >
                              <Box className={classes.accordianTitleWrapper}>
                                <Box className={classes.accordianTitle}>
                                  <Box>Document Type Tags</Box>
                                </Box>
                                <Box>
                                  <Search
                                    value={searchText[selectedKey?.id]}
                                    onChange={(e) => {
                                      handleSearch(
                                        e.target.value,
                                        selectedKey?.id
                                      )
                                    }}
                                  />
                                </Box>
                              </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                              <SortTable
                                isDocumentType
                                tagData={tags[selectedKey.id]}
                                tagKey={selectedKey.id}
                                tagValueType={tags[selectedKey.id]?.value_type}
                                tagKeyEditable={tags[selectedKey.id]?.editable}
                                canCreate={canCreate}
                                canEdit={canEdit}
                                canDelete={canDelete}
                                showConfirmDialog={showConfirmDialog}
                                selectedRows={selectedRows[selectedKey?.id]}
                                setSelectedRows={(e) =>
                                  handleSelectedRows(e, selectedKey?.id)
                                }
                              />
                            </AccordionDetails>
                          </Accordion>
                        </Box>
                      </Section>
                    </Container>
                  </Box>
                ) : selectedKey?.type === 'settings' ? (
                  <Box className={classes.mainPaneContainer}>
                    <Container>
                      <SectionFixed>
                        <Box className={classes.titleText}>
                          Tag Center Settings
                        </Box>
                        <Box className={classes.subText}>
                          Manage who can create, edit, or delete tags. Keep your
                          content neatly categorized and easily searchable with
                          the Tag Center.
                        </Box>
                      </SectionFixed>
                      <Section overFlow>
                        <Box className={classes.accessControl}>
                          <table>
                            <tr>
                              <th>Role</th>
                              <th>Create</th>
                              <th>Edit</th>
                              <th>Delete</th>
                            </tr>
                            <tr>
                              <td>Admin</td>
                              <td>
                                <Checkbox
                                  checked={
                                    accessControl?.admin?.tags?.create ?? false
                                  }
                                  disabled={!isOwner}
                                  onChange={() =>
                                    handleAccessControl(
                                      'create',
                                      'admin',
                                      accessControl?.admin?.tags?.create ??
                                        false
                                    )
                                  }
                                  disableRipple
                                />
                              </td>
                              <td>
                                <Checkbox
                                  checked={
                                    accessControl?.admin?.tags?.edit ?? false
                                  }
                                  disabled={!isOwner}
                                  onChange={() =>
                                    handleAccessControl(
                                      'edit',
                                      'admin',
                                      accessControl?.admin?.tags?.edit ?? false
                                    )
                                  }
                                  disableRipple
                                />
                              </td>
                              <td>
                                <Checkbox
                                  checked={
                                    accessControl?.admin?.tags?.delete ?? false
                                  }
                                  disabled={!isOwner}
                                  onChange={() =>
                                    handleAccessControl(
                                      'delete',
                                      'admin',
                                      accessControl?.admin?.tags?.delete ??
                                        false
                                    )
                                  }
                                  disableRipple
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>User</td>
                              <td>
                                <Checkbox
                                  checked={
                                    accessControl?.user?.tags?.create ?? false
                                  }
                                  onChange={() =>
                                    handleAccessControl(
                                      'create',
                                      'user',
                                      accessControl?.user?.tags?.create ?? false
                                    )
                                  }
                                  disableRipple
                                />
                              </td>
                              <td>
                                <Checkbox
                                  checked={
                                    accessControl?.user?.tags?.edit ?? false
                                  }
                                  onChange={() =>
                                    handleAccessControl(
                                      'edit',
                                      'user',
                                      accessControl?.user?.tags?.edit ?? false
                                    )
                                  }
                                  disableRipple
                                />
                              </td>
                              <td>
                                <Checkbox
                                  checked={
                                    accessControl?.user?.tags?.delete ?? false
                                  }
                                  onChange={() =>
                                    handleAccessControl(
                                      'delete',
                                      'user',
                                      accessControl?.user?.tags?.delete ?? false
                                    )
                                  }
                                  disableRipple
                                />
                              </td>
                            </tr>
                          </table>
                        </Box>
                      </Section>
                    </Container>
                  </Box>
                ) : (
                  <Box className={classes.mainPaneContainer}>
                    <Container>
                      <SectionFixed>
                        <Box className={classes.titleText}>
                          {selectedKey?.id === 'all'
                            ? 'All Tags'
                            : selectedKey?.id}
                        </Box>
                        <Box className={classes.subText}>
                          Manage tags effortlessly throughout the app. Add,
                          edit, or delete tags to streamline organization and
                          enhance your library and assets management experience.
                          Keep your content neatly categorized and easily
                          searchable with the Tag Center.
                        </Box>
                      </SectionFixed>
                      <Section overFlow>
                        <Box className={classes.accordianWrapper}>
                          {selectedKey?.id && selectedKey?.type === 'tag' ? (
                            <Accordion
                              square
                              TransitionProps={{
                                timeout: { appear: 1, enter: 1, exit: 4 }
                              }}
                              defaultExpanded={true}
                              expanded={!!expanded[selectedKey?.id]}
                              onChange={() =>
                                handleAccordianChange(selectedKey?.id)
                              }
                            >
                              <AccordionSummary
                                expandIcon={
                                  <Box className={classes.secondaryColor}>
                                    {expanded[selectedKey?.id]
                                      ? 'Collapse'
                                      : 'Expand'}
                                  </Box>
                                }
                              >
                                <Box className={classes.accordianTitleWrapper}>
                                  <Box className={classes.accordianTitle}>
                                    {getTagCenterIcon(
                                      tags[selectedKey?.id]?.type,
                                      tags[selectedKey.id]?.editable
                                    )}
                                    <Box>
                                      {selectedKey?.id}
                                      {tags[selectedKey?.id]?.data?.length - 1 >
                                        0 &&
                                        `(${
                                          tags[selectedKey?.id]?.data?.length -
                                          1
                                        })`}
                                    </Box>
                                  </Box>
                                  <Box className={classes.visibilityIcon}>
                                    <Box>
                                      {getVisibiltyIcon(
                                        tags[selectedKey?.id]?.key_type
                                      ).map((icon) => icon)}
                                    </Box>
                                    <Search
                                      value={searchText[selectedKey?.id]}
                                      onChange={(e) => {
                                        handleSearch(
                                          e.target.value,
                                          selectedKey?.id
                                        )
                                      }}
                                    />
                                  </Box>
                                </Box>
                              </AccordionSummary>
                              <AccordionDetails>
                                <SortTable
                                  tagData={tags[selectedKey.id]}
                                  tagKey={selectedKey.id}
                                  tagValueType={
                                    tags[selectedKey.id]?.value_type
                                  }
                                  tagKeyEditable={
                                    tags[selectedKey.id]?.editable
                                  }
                                  showConfirmDialog={showConfirmDialog}
                                  canCreate={canCreate}
                                  canEdit={canEdit}
                                  canDelete={canDelete}
                                  selectedRows={selectedRows[selectedKey?.id]}
                                  setSelectedRows={(e) =>
                                    handleSelectedRows(e, selectedKey?.id)
                                  }
                                />
                              </AccordionDetails>
                            </Accordion>
                          ) : Object.keys(tags).length > 0 ? (
                            Object.keys(tags).map((key, index) => {
                              const {
                                type,
                                editable,
                                value_type,
                                key_type,
                                data
                              } = tags[key]
                              const isDocumentType =
                                value_type?.includes('document_type')
                              const length = data?.length - 1 || 0
                              return (
                                !isDocumentType && (
                                  <Accordion
                                    square
                                    TransitionProps={{
                                      timeout: { appear: 1, enter: 1, exit: 4 }
                                    }}
                                    defaultExpanded={false}
                                    expanded={!!expanded[key]}
                                    onChange={() => handleAccordianChange(key)}
                                  >
                                    <AccordionSummary
                                      expandIcon={
                                        <Box className={classes.secondaryColor}>
                                          {expanded[key]
                                            ? 'Collapse'
                                            : 'Expand'}
                                        </Box>
                                      }
                                    >
                                      <Box
                                        className={
                                          classes.accordianTitleWrapper
                                        }
                                      >
                                        <Box className={classes.accordianTitle}>
                                          {getTagCenterIcon(type, editable)}
                                          <Box>
                                            {key}
                                            {length > 0 && ` (${length})`}
                                          </Box>
                                        </Box>
                                        <Box className={classes.visibilityIcon}>
                                          {getVisibiltyIcon(key_type).map(
                                            (icon) => icon
                                          )}
                                          <Search
                                            value={searchText[key]}
                                            onChange={(e) => {
                                              handleSearch(e.target.value, key)
                                            }}
                                          />
                                        </Box>
                                      </Box>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      <SortTable
                                        tagData={tags[key]}
                                        tagKey={key}
                                        canCreate={canCreate}
                                        canEdit={canEdit}
                                        canDelete={canDelete}
                                        tagValueType={
                                          tags[selectedKey.id]?.value_type
                                        }
                                        showConfirmDialog={showConfirmDialog}
                                        tagKeyEditable={tags[key]?.editable}
                                        selectedRows={selectedRows[key]}
                                        setSelectedRows={(e) =>
                                          handleSelectedRows(e, key)
                                        }
                                      />
                                    </AccordionDetails>
                                  </Accordion>
                                )
                              )
                            })
                          ) : (
                            <Box className={classes.noTagsText}>
                              No Tags Found
                            </Box>
                          )}
                        </Box>
                      </Section>
                    </Container>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Box>
          {renderEditDialog()}
          {ConfirmDialog}
        </Section>
      )}
    </Container>
  )
}

export default TagCenterSettings
