import { useState, useEffect, useRef } from 'react'
import { Grid } from '@material-ui/core'
import Typography from '../../components/Typography'
import FavoriteIcon from '@mui/icons-material/Favorite'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import Image from '../../components/ImageElement'
import { IconButton } from '@mui/material'
import { isEmpty, isNumber } from 'lodash'
import { useStyles } from './styles'
import Highlighted from '../../utils/Highlight/Highlight'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import { Button } from '../../components'
import Box from '@mui/material/Box'
import TextLineLimiter from '../../components/TextLineLimiter'
import ImageIcon from '@mui/icons-material/Image'
import Popover from '@mui/material/Popover'
import clsx from 'clsx'
import { CheckAndRenderImage } from '../../utils/AWS'
import AddToCollection from '../LibraryCard/AddToCollection'
import Tooltip from '@mui/material/Tooltip'
import { useDispatch } from 'react-redux'
import { stripHTML, stripMark } from '../../utils/CopyHTML'
import { createLibraryDocumentEditor } from '../../store/Library/Actions'
import ContentCopyIcon from '@mui/icons-material/ContentCopyOutlined'
import SvgIcon from '@material-ui/core/SvgIcon'
import { ReactComponent as SimilarIcon } from '../../assets/images/Similar_Icon.svg'
import { ReactComponent as TempIcon } from '../../assets/images/TempFile.svg'
import { ReactComponent as IsolateFilterIcon } from '../../assets/images/Isolate_Filter.svg'
import trackEvent from '../../utils/TrackEvent/TrackEvent'
import mixpanelEvents from '../../config/mixpanelEvents'
import { useColor } from '../../ThemeContext'
import { useLocation, useSearchParams } from 'react-router-dom'
import { Badge } from '@mui/base'
import Button2 from '../../components/Button/Button2'

const cleanMarkTags = (htmlString) => {
  const regex = /(<[^>]*)(<mark>(.*?)<\/mark>)(.*?>)/g
  return stripClassNames(htmlString.replace(regex, '$1$3$4'))
}

const stripClassNames = (htmlString) => {
  const strippedString = htmlString.replace(/class\s*=\s*["'][^"']*["']/gi, '')
  return strippedString
}

const TextResult = (props) => {
  const dispatch = useDispatch()
  const {
    keyId = '',
    result = {},
    handlePDFViewer = () => {},
    handleProjectViewer = () => {},
    handleSave,
    handleCopy,
    optionHiddenOpen = true,
    hideTextLength = 150,
    enableSave = true,
    searchKeyword = '',
    src = '',
    showVariations = false,
    savedList = [],
    handleVariation,
    handleShowImage = () => {},
    screenshots = [],
    s3Obj = {},
    enableAddtoNarratives = false,
    result_type = '',
    disableEdit = false,
    handleFilterByProposal = () => {},
    showTempDataTag = false,
    selectedFile = ''
  } = props

  const classes = useStyles()
  const {
    displayPageName,
    copyButton,
    favoriteIcon,
    dragIndicator,
    hiddenRootContainer,
    visibleRootContainer,
    displayContent,
    resultTitle,
    cardAction,
    activeContainer,
    imageWrapper,
    imageContainer,
    similarImage
  } = classes

  const {
    data = {},
    content: newContent = {},
    proposal_filename = '',
    page_number = '',
    variationsInResult = [],
    similarity = [],
    is_temp_data = false,
    proposal_id = '',
    id = ''
  } = result

  const location = useLocation()
  const [searchParams] = useSearchParams()

  const contentIdParams = searchParams.get('content_id')

  const variationsLength = Array.isArray(variationsInResult)
    ? variationsInResult.length
    : 0
  const similarityLength = Array.isArray(similarity) ? similarity.length : 0

  const variationsCount = variationsLength + similarityLength

  const finalData = !isEmpty(newContent) ? newContent : data
  let { title = '', content = '' } = finalData
  title = cleanMarkTags(title)
  let projectContent = ''

  if (result_type === 'project') {
    try {
      projectContent = JSON.parse(content ? content.replace(/'/g, '"') : {})
    } catch (e) {
      projectContent = {}
    }
    content = projectContent?.project_description ?? ''
  }
  content = cleanMarkTags(content)

  const [saved, setSaved] = useState()

  useEffect(() => {
    const { content_id = '' } = result
    const saveValue = savedList.includes(content_id)
    setSaved(!saveValue)
  }, [savedList])

  useEffect(() => {
    if (contentIdParams) {
      const elementId = `text-result-${contentIdParams}`
      const element = document.getElementById(elementId)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [location])

  const handleSaveClick = () => {
    handleSave(result, saved)
    setSaved(!saved)
  }

  const openPdf = () => {
    if (!disableEdit) {
      trackEvent(
        mixpanelEvents.DASHBOARD_RESULT_PROPOSAL_OPENED,
        'SUCCESS',
        {},
        {
          search_keyword: result?.keywords,
          proposal_filename: result?.proposal_filename
        }
      )
      handlePDFViewer(result)
    }
  }

  const handleDrag = (event, content) => {
    const newContent = `<html> <body> <b>${title}</b> <br> ${content}  </body></html>`
    event.dataTransfer.setData('text/html', newContent)
    event.dataTransfer.setData('dragcustomrichtext', JSON.stringify(true))
  }

  const [openedPopover, setOpenedPopover] = useState(false)
  const popoverAnchor = useRef(null)
  const popoverEnter = (e) => {
    setOpenedPopover(true)
  }

  const popoverLeave = (e) => {
    setOpenedPopover(false)
  }

  const scrolldiv = useRef(null)

  const handleScroll = (event) => {
    const delta = Math.max(
      -1,
      Math.min(1, event.nativeEvent.wheelDelta || -event.nativeEvent.detail)
    )
    scrolldiv.current.scrollLeft -= delta * 40
  }

  const handleOpenProject = () => {
    if (!disableEdit) {
      trackEvent(
        mixpanelEvents.DASHBOARD_RESULT_PROJECT_OPENED,
        'SUCCESS',
        {},
        {
          search_keyword: result?.keywords,
          proposal_filename: result?.proposal_filename
        }
      )
      handleProjectViewer(result, projectContent)
    }
  }

  const handleCollectionUpdate = (newValues) => {
    const {
      content_id,
      data: dataObj = {},
      es_id,
      proposal_id,
      page_number,
      searchKeyword,
      proposal_filename
    } = result
    const { content, title } = dataObj
    const metaData = {
      src: 'search',
      content_id,
      es_id,
      proposal_id,
      pageIndex: page_number,
      searchKeyword,
      selectedText: content,
      selectedTitle: title,
      proposal_filename
    }
    const params = {
      document_content: null,
      document_html: stripMark(content),
      document_name: stripHTML(title),
      collections: newValues,
      metadata: metaData
    }
    dispatch(createLibraryDocumentEditor(params))
  }

  const { selectedColor } = useColor()

  const isEditorDrag = src === 'editor'
  return (
    <div key={keyId}>
      {content && (
        <div
          className={clsx(
            optionHiddenOpen ? hiddenRootContainer : visibleRootContainer,
            openedPopover ? activeContainer : ''
          )}
          id={'text-result-' + id}
          style={{
            border:
              contentIdParams === id
                ? `2px solid ${selectedColor}`
                : '1px solid #DCD7D7'
          }}
        >
          <Box sx={{ display: 'flex' }}>
            <Box className="flex items-center">
              {screenshots.length > 0 && (
                <CheckAndRenderImage
                  style={{
                    height: '140px',
                    width: '120px',
                    objectFit: 'contain',
                    // border: '1px solid #E5E5E5',
                    borderRadius: '5px',
                    margin: 0,
                    background: '#FFF',
                    overflow: 'hidden'
                  }}
                  src={screenshots[0].src}
                  s3Obj={s3Obj}
                  onClick={() => handleShowImage(screenshots, 0)}
                />
              )}
            </Box>
            <Box
              onDoubleClick={openPdf}
              sx={{
                width: '100%',
                padding: '10px',
                borderLeft: '1px solid var(--grey-200)',
                cursor: 'pointer',
                '&:hover': {
                  background: 'var(--grey-100)'
                  // margin: "10px 0px"
                }
              }}
            >
              {result_type === 'section' ? (
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div onClick={openPdf}>
                    <Typography className="text-lg font-medium">
                      <TextLineLimiter
                        content={
                          <span dangerouslySetInnerHTML={{ __html: title }} />
                        }
                        limit={1}
                        startAt="<mark>"
                        isStartAtHTML="mark"
                      />
                    </Typography>
                    <a id="open-pdf-link" onClick={openPdf}>
                      <div className="text-sm text-grey-800">
                        {proposal_filename}
                        {isNumber(page_number) && (
                          <span className="font-medium ml-2">
                            - Page {page_number}
                          </span>
                        )}{' '}
                      </div>
                    </a>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      gap: '10px',
                      alignItems: 'flex-start'
                    }}
                  >
                    {showTempDataTag && is_temp_data && (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          color: '#797979',
                          '& svg': { fontSize: '30px' }
                        }}
                      >
                        <Tooltip title="Temporary Data">
                          <SvgIcon>
                            <TempIcon />
                          </SvgIcon>
                        </Tooltip>
                      </Box>
                    )}
                    <div
                      className="bg-grey-100 font-medium rounded-xl text-grey-800 p-1 text-xs px-3"
                      onClick={openPdf}
                      style={{
                        border: '1px solid var(--grey-200)'
                      }}
                    >
                      {result_type === 'section' && result?.displayTag
                        ? result.displayTag
                        : result?.document_id
                        ? 'Document'
                        : 'Proposal'}
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  {result_type === 'project' ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start'
                      }}
                    >
                      <div>
                        <div onClick={handleOpenProject}>
                          <Typography className={resultTitle}>
                            <TextLineLimiter
                              content={
                                <span
                                  dangerouslySetInnerHTML={{ __html: title }}
                                />
                              }
                              limit={1}
                              startAt="<mark>"
                              isStartAtHTML="mark"
                            />
                          </Typography>
                        </div>
                        <a id="open-pdf-link" onClick={openPdf}>
                          <div className="text-sm text-grey-800">
                            {proposal_filename}
                            {isNumber(page_number) && (
                              <span className="font-medium ml-2">
                                - Page {page_number}
                              </span>
                            )}{' '}
                          </div>
                        </a>
                      </div>
                      <div style={{ display: 'flex', gap: '10px' }}>
                        {showTempDataTag && is_temp_data && (
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              color: '#797979',
                              '& svg': { fontSize: '30px' }
                            }}
                          >
                            <Tooltip title="Temporary Data">
                              <SvgIcon>
                                <TempIcon />
                              </SvgIcon>
                            </Tooltip>
                          </Box>
                        )}
                        <div
                          className="bg-grey-100 font-medium rounded-xl text-grey-800 p-1 text-xs px-3"
                          style={{
                            border: '1px solid var(--grey-200)'
                          }}
                          onClick={handleOpenProject}
                        >
                          {result_type === 'project' && 'Project'}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent:
                          showTempDataTag && is_temp_data
                            ? 'space-between'
                            : 'start'
                      }}
                    >
                      <div>
                        <Typography className={resultTitle}>
                          <TextLineLimiter
                            content={
                              <span
                                dangerouslySetInnerHTML={{ __html: title }}
                              />
                            }
                            limit={1}
                            startAt="<mark>"
                            isStartAtHTML="mark"
                          />
                        </Typography>
                        <a id="open-pdf-link" onClick={openPdf}>
                          <div className="text-sm text-grey-800 ">
                            {proposal_filename}
                            {isNumber(page_number) && (
                              <span className="font-medium ml-2">
                                - Page {page_number}
                              </span>
                            )}{' '}
                          </div>
                        </a>
                      </div>
                      {showTempDataTag && is_temp_data && (
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            color: '#797979',
                            '& svg': { fontSize: '30px' }
                          }}
                        >
                          <Tooltip title="Temporary Data">
                            <SvgIcon>
                              <TempIcon />
                            </SvgIcon>
                          </Tooltip>
                        </Box>
                      )}
                    </div>
                  )}
                </>
              )}
              <div
                className="text-content mt-2"
                style={{
                  width: '85%'
                }}
                draggable={isEditorDrag}
                onDragStart={(event) => {
                  handleDrag(event, content)
                }}
              >
                <span>
                  <Typography className="text-xs search-content text-grey-600 ">
                    {content && (
                      <>
                        {isEditorDrag ? (
                          <div className={dragIndicator}>
                            <DragIndicatorIcon />
                            <TextLineLimiter
                              content={
                                <span
                                  dangerouslySetInnerHTML={{ __html: content }}
                                />
                              }
                              limit={2}
                              startAt="<mark>"
                              isStartAtHTML="mark"
                            />
                          </div>
                        ) : (
                          <TextLineLimiter
                            content={
                              <span
                                dangerouslySetInnerHTML={{ __html: content }}
                              />
                            }
                            limit={2}
                            startAt="<mark>"
                            isStartAtHTML="mark"
                          />
                        )}
                      </>
                    )}
                  </Typography>
                </span>
              </div>
              <Grid
                id="cardActions"
                container
                justifyContent={
                  handleVariation && variationsCount > 0
                    ? 'space-between'
                    : 'flex-end'
                }
                style={{
                  marginTop: handleVariation && variationsCount > 0 ? '10px' : 0
                }}
              >
                {handleVariation && variationsCount > 0 && (
                  <Box className="relative">
                    <Button2
                      secondary
                      disabled={parseInt(variationsCount) < 1}
                      onClick={(e) => {
                        e.stopPropagation()
                        handleVariation(true)
                      }}
                    >
                      <SvgIcon
                        style={{
                          width: '15px',
                          height: '15px'
                        }}
                      >
                        <SimilarIcon />
                      </SvgIcon>
                      <div style={{ marginLeft: '7px', fontSize: '12px' }}>
                        {showVariations
                          ? `Hide Similar Results`
                          : `Similar Results`}
                      </div>
                    </Button2>

                    {!showVariations && (
                      <span
                        className="absolute top-0 right-0 text-xxs bg-grey-700 rounded-full size-4 text-white grid place-content-center font-medium"
                        style={{
                          top: '-6px',
                          right: '-6px'
                        }}
                      >
                        {variationsCount}
                      </span>
                    )}
                  </Box>
                )}
                <Box className={cardAction}>
                  {/* {proposal_id && (
                    <Tooltip title="Add source document filter">
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation()
                          trackEvent(
                            mixpanelEvents.DASHBOARD_RESULT_SOURCE_DOCUMENT_FILTER,
                            'SUCCESS',
                            {}
                          )
                          handleFilterByProposal(result)
                        }}
                        sx={{ color: '#797979' }}
                      >
                        <SvgIcon style={{ width: '15px', height: '15px' }}>
                          <IsolateFilterIcon />
                        </SvgIcon>
                      </IconButton>
                    </Tooltip>
                  )} */}
                  <Tooltip title="Copy">
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation()
                        handleCopy(content, title)
                      }}
                    >
                      <ContentCopyIcon
                        sx={{ color: '#797979', width: '15px', height: '15px' }}
                      />
                    </IconButton>
                  </Tooltip>
                  {enableAddtoNarratives && result_type === 'narrative' && (
                    <AddToCollection
                      collections={data.collections}
                      onSelect={handleCollectionUpdate}
                    />
                  )}
                  {enableSave && (
                    <div className={cardAction}>
                      <IconButton onClick={handleSaveClick}>
                        {saved ? (
                          <FavoriteBorderIcon
                            style={{ width: '15px', height: '15px' }}
                          />
                        ) : (
                          <FavoriteIcon
                            className={favoriteIcon}
                            style={{ width: '15px', height: '15px' }}
                          />
                        )}
                      </IconButton>
                    </div>
                  )}
                  {/* {
                screenshots.length > 0 &&
                <>
                  <span ref={popoverAnchor}
                    onMouseEnter={popoverEnter}
                  // onMouseLeave={popoverLeave}
                  >
                    <IconButton onClick={() => {
                      handleShowImage(screenshots);
                    }}>
                      <ImageIcon sx={{ color: '#797979', width: "15px", height: "15px" }} />
                    </IconButton></span>
                  <Popover
                    open={openedPopover}
                    anchorEl={popoverAnchor.current}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    elevation={10}
                    PaperProps={{ onMouseEnter: popoverEnter, onMouseLeave: popoverLeave }}
                  >
                    <Box className={imageContainer} ref={scrolldiv} onWheel={handleScroll}>
                      {screenshots.map((image, index) =>
                        <CheckAndRenderImage
                          className={imageWrapper}
                          src={image.src}
                          key={index}
                          s3Obj={s3Obj}
                          onClick={() => handleShowImage(screenshots, index)}
                        />
                      )}
                    </Box>
                  </Popover>
                </>
              } */}
                </Box>
              </Grid>
            </Box>
          </Box>
        </div>
      )}
    </div>
  )
}

export default TextResult
