import { Box } from '@mui/material'
import React, { useState } from 'react'
import LexicalEditor from '../../components/Lexical'
import { $generateHtmlFromNodes } from '@lexical/html'
import Button2 from '../../components/Button/Button2'
import { PlusIcon } from '../../components/Icons/Icons'

const Notebook = () => {
  // const [editedHtmlString, setEditedHtmlString] = useState(
  //   `<h1 class="PlaygroundEditorTheme__h1"><span>Introducing Notebooks! 🥳     </span></h1><p class="PlaygroundEditorTheme__paragraph" dir="ltr"><span>Notebooks are a powerful way to collate, analyze, and share Joist         insights with others.       </span></p><ul class="PlaygroundEditorTheme__ul"><li value="1" class="PlaygroundEditorTheme__listItem"><span>Preparing for Proposal </span></li><li value="2" class="PlaygroundEditorTheme__listItem"><span>Winning Strategy </span></li></ul><p class="PlaygroundEditorTheme__paragraph"><span> </span></p><p class="PlaygroundEditorTheme__paragraph" dir="ltr"><span>There’s no limit to how many notebooks you can create, or how you can         share them within your organisation.</span></p>`
  // )

  const [editedHtmlString, setEditedHtmlString] = useState(`<h1>Testigng</h1>`)
  const [notes, setNotes] = useState([
    {
      id: 1,
      title: 'Note Template',
      content: `<h1 class="PlaygroundEditorTheme__h1"><span>Introducing Notebooks! 🥳     </span></h1><p class="PlaygroundEditorTheme__paragraph" dir="ltr"><span>Notebooks are a powerful way to collate, analyze, and share Joist         insights with others.       </span></p><ul class="PlaygroundEditorTheme__ul"><li value="1" class="PlaygroundEditorTheme__listItem"><span>Preparing for Proposal </span></li><li value="2" class="PlaygroundEditorTheme__listItem"><span>Winning Strategy </span></li></ul><p class="PlaygroundEditorTheme__paragraph"><span> </span></p><p class="PlaygroundEditorTheme__paragraph" dir="ltr"><span>There’s no limit to how many notebooks you can create, or how you can         share them within your organisation.</span></p>`
    },
    {
      id: 2,
      title: 'Note',
      content: ``
    }
  ])

  const [selectedNote, setSelectedNote] = useState(notes[0])
  const handleEditorChange = (currentEditorState, editor) => {
    // editor.update(() => {
    //   // setUnSavedChanges(true)
    //   // setCurrentEditorState(currentEditorState)
    //   const htmlString = $generateHtmlFromNodes(editor, null)
    //   console.log('htmlString 2', htmlString)
    //   setEditedHtmlString(htmlString)
    // })
  }

  return (
    <div className="flex h-full">
      <div
        className="flex flex-col gap-1"
        style={{
          width: '30%',
          borderRight: '1px solid var(--grey-200)'
        }}
      >
        <div className="flex justify-between items-center p-2 border-b-1">
          <p className="text-sm font-medium m-0">All Notes</p>
          <Button2
            secondary
            noOutline
            onClick={() => {
              setNotes([
                ...notes,
                {
                  id: notes.length + 1,
                  title: 'Note ' + (notes.length + 1),
                  content: ''
                }
              ])

              setSelectedNote({
                id: notes.length + 1,
                title: 'Note ' + (notes.length + 1),
                content: ''
              })
            }}
            style={{
              padding: '4px'
            }}
          >
            <PlusIcon className="size-4" />
          </Button2>
        </div>
        <div
          style={{
            padding: '6px'
          }}
        >
          {notes.map((note) => {
            return (
              <div
                key={note.id}
                className="cursor-pointer p-2 px-4 rounded-lg"
                style={{
                  backgroundColor:
                    selectedNote.id === note.id ? 'var(--grey-100)' : ''
                }}
                onClick={() => {
                  setSelectedNote(note)
                }}
              >
                <p className="text-xxs m-0 font-medium">{note.title}</p>
                {/* <div
                dangerouslySetInnerHTML={{
                  __html: note.content
                }}
              ></div> */}
              </div>
            )
          })}
        </div>
      </div>
      <Box
        id="notes-editor"
        style={{
          height: 'calc(100% - 50px)',
          width: '70%',
          padding: '0 15px',
          boxsizing: 'border-box'
        }}
      >
        <input
          type="text"
          className="input-base text-lg font-medium"
          style={{
            padding: '10px 4px'
          }}
          placeholder="Enter note title"
          onChange={(e) => {
            setSelectedNote({
              ...selectedNote,
              title: e.target.value
            })
          }}
          value={selectedNote.title}
        />
        <LexicalEditor
          miniEditor={true}
          documentHtml={selectedNote.content}
          // initialEditorContent={{}}
          // unSavedChanges={unSavedChanges}
          // setUnSavedChanges={}
          handleEditorChange={handleEditorChange}
          key={selectedNote.id}
        />
      </Box>
    </div>
  )
}

export default Notebook
