import { makeStyles } from '@mui/styles'
import { useColor } from '../../ThemeContext'

const useStyles = makeStyles((theme) => {
  const { selectedColor } = useColor() // Get the selected color from the context

  return {
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    filterContainer: {
      display: 'flex',
      alignItems: 'center'
    },
    childContainer: {
      paddingRight: '5px'
      /*  '& >div > div':{
       width:"400px",
       zIndex:'1000'
     } */
    },
    filterDivider: {
      // borderBottom: '1px solid #E5E5E5',
      // padding: '4px',
      paddingLeft: '0'
    },
    iconContainer: {
      display: 'flex',
      padding: '5px 2.5px',
      cursor: 'pointer',
      alignItems: 'center'
    },
    iconActive: {
      color: theme.palette.primary.main,
      display: 'flex',
      alignItems: 'center',
      fontSize: '12px',
      padding: '0px'
    },
    iconInActive: {
      color: theme.palette.primary.secondary,
      display: 'flex',
      alignItems: 'center',
      fontSize: '12px',
      padding: '0px'
    },
    select: {
      borderRadius: '24px'
    },
    formControl: {
      minWidth: 150,
      margin: '5px 15px'
    },

    filterLabel: {
      top: -8
    },
    searchText: {
      borderRadius: '24px',
      padding: 0,
      '& label': {
        top: -8
      }
    },
    filterOption: {
      display: 'flex',
      paddingLeft: '20px',
      marginTop: '5px',
      flexDirection: 'column'
    },
    filterButton: {
      display: 'flex',
      marginTop: '5px',
      alignItems: 'center'
    },
    refreshContainer: {
      display: 'flex',
      padding: '0px 5px 0px 0px'
    },
    refreshIcon: {
      fontSize: '20px'
    },
    keyValueContainer: {
      marginTop: '5px'
    },
    keyValue: {
      padding: '4px 14px',
      backgroundColor: '#FFF',
      borderRadius: '4px',
      border: 'solid 1px #E5E5E5',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: 1.36,
      alignItems: 'center',
      background: '#FFF',
      boxSizing: 'border-box',
      color: '#515151',
      cursor: 'pointer',
      display: 'inline-flex',
      marginRight: '5px',
      maxWidth: '300px',
      flexWrap: 'nowrap',
      outline: 'currentcolor none medium !important',
      textAlign: 'left',
      textDecoration: 'none',
      transition:
        'background 0.1s ease-out 0s, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s',
      verticalAlign: 'middle',
      whiteSpace: 'nowrap',
      width: 'auto',
      textTransform: 'none'
    },
    keyValueTextInput: {
      maxWidth: '70px',
      '& input': {
        padding: '0px 5px'
      }
    },
    addIcon: {
      width: '12px !important',
      height: '14px !important',
      paddingRight: '5px'
    },
    buttonText: {
      fontFamily: 'PoppinsRegular',
      color: '#ffffff',
      fontWeight: '600',
      lineHeight: '24px',
      letterSpacing: '0.01em',
      margin: '0px',
      textTransform: 'capitalize'
    },
    buttonWrapper: {
      display: 'flex',
      alignItems: 'center',
      background: selectedColor,
      color: '#ffffff',
      fontFamily: 'PoppinsRegular',
      borderRadius: '6px',
      cursor: 'pointer',
      padding: '4px 10px',
      '&:hover': {
        background: '#195ccb'
      }
    },
    deleteWrapper: {
      paddingLeft: '15px'
    },
    searchButtonWrapper: {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: '#ffffff',
      fontFamily: 'PoppinsRegular',
      borderRadius: '6px',
      border: '1px ' + selectedColor,
      cursor: 'pointer',
      padding: '4px 10px',
      '&:hover': {
        background: '#ffffff'
      }
    },
    searchButtonText: {
      fontFamily: 'PoppinsRegular',
      fontWeight: '600',
      lineHeight: '24px',
      letterSpacing: '0.01em',
      margin: '0px',
      textTransform: 'capitalize',
      color: selectedColor
    },
    tab: {
      display: 'flex',
      alignItems: 'center',
      background: '#ffffff',
      color: '#000',
      fontFamily: 'PoppinsRegular',
      // borderRadius: "6px",
      cursor: 'pointer',
      borderColor: selectedColor,
      padding: '4px 10px',
      '&:hover': {
        background: selectedColor,
        color: '#ffffff'
      }
    },
    tabSelected: {
      display: 'flex',
      alignItems: 'center',
      background: 'var(--grey-800)',
      color: '#ffffff',
      fontFamily: 'PoppinsRegular',
      // borderRadius: "6px",
      cursor: 'pointer',
      padding: '4px 10px',
      '&:hover': {
        background: selectedColor
      }
    }
  }
})

export { useStyles }
