import { useEffect, useState } from 'react'
import * as React from 'react'
import { useStyles } from './styles'
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'
import { toolbarPlugin } from '@react-pdf-viewer/toolbar'
import Grid from '@mui/material/Grid'
import Tab from '../../components/Tabs'
import {
  Viewer,
  SpecialZoomLevel,
  Position,
  Tooltip,
  MinimalButton,
  Icon
} from '@react-pdf-viewer/core'
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation'
import { ReactComponent as Robot } from '../../assets/images/robot.svg'
import SvgIcon from '@material-ui/core/SvgIcon'
import IconButton from '@mui/material/IconButton'
import { ReactComponent as BackImg } from '../../assets/images/back.svg'
import Box from '@material-ui/core/Box'
import { Section, SectionFixed, Container } from '../../components/Container'
import ChatBot from '../../containers/ChatBot'
import { Resizable } from 'react-resizable'
import { useResizeDetector } from 'react-resize-detector'
import ComplianceMatrix from './ComplianceMatrix'
import { useSelector, useDispatch } from 'react-redux'
import { searchPlugin } from '@react-pdf-viewer/search'
import ShareIcon from '@mui/icons-material/Share'
import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import { Button, ToasterAlert } from '../../components'
import TextField from '@mui/material/TextField'
import Loader from '../../components/Loader'
import { ShareRfx } from '../../store/api'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import Error from '../../components/Error'
import Popover from '@mui/material/Popover'
import trackEvent from '../../utils/TrackEvent/TrackEvent'
import { checkUserRoleViewer } from '../../utils/User'
import Button2 from '../../components/Button/Button2'

const PDFViewer = (props) => {
  const {
    url,
    isAnalytics,
    PDFData,
    setShowPDF = () => {},
    shareType = 'private',
    pageNo = 1
  } = props
  const classes = useStyles()
  const {
    webviewer,
    page,
    infoWrapper,
    infoContainer,
    borderBot,
    titleContainer,
    iconButton,
    icon,
    pdfTitle,
    shareFileIcon,
    pdfHeader
  } = classes
  const toolbarPluginInstance = toolbarPlugin()
  const { Toolbar, renderDefaultToolbar } = toolbarPluginInstance
  const [activeTab, setActiveTab] = useState(0)
  const [aiPlugin, setAIPlugin] = useState(null)
  const [showError, setShowError] = useState(!!isAnalytics)
  const [pdfCurrentPage, setPdfCurrentPage] = useState(0)
  const { document_name = '' } = PDFData
  const { width, height, ref } = useResizeDetector()
  const [pdfWidth, setPdfWidth] = useState(0)
  const [toolbarWidth, setToolbarWidth] = useState(0)

  const analysisReport = PDFData?.content[0]?.analysis_report || {}
  const matrix = analysisReport?.['Compliance List'] || []
  const [complianceMatrix, setComplianceMatrix] = useState(matrix)
  const dispatch = useDispatch()
  const [openShareDialog, setOpenShareDialog] = useState(false)
  const [selectedOption, setSelectedOption] = useState('custom')
  const [customValue, setCustomValue] = useState('14')
  const [linkLoading, setLinkLoading] = useState(false)
  const [shareableLink, setShareableLink] = useState('')
  const [isCopied, setIsCopied] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)

  const isUserViewer = checkUserRoleViewer()
  const currentUserName = useSelector(
    (state) => state.authenticate.user?.domain?.user_name || ''
  )

  useEffect(() => {
    handleOnResize()
  }, [width])

  const handleOnResize = (event, obj) => {
    if (event && obj) {
      const newEWidth = obj.size.width
      const newSWidth = width - obj.size.width - 1
      if ((newSWidth / width) * 100 > 30 && (newEWidth / width) * 100 > 30) {
        setPdfWidth(newEWidth)
        setToolbarWidth(newSWidth)
      }
    } else {
      if (pdfWidth && toolbarWidth) {
        const sWidth = toolbarWidth
        const eWidth = width - sWidth - 1
        setPdfWidth(eWidth)
        setToolbarWidth(sWidth)
      } else {
        const eWidth = Math.round((60 / 100) * width)
        const sWidth = width - eWidth - 1
        setPdfWidth(eWidth)
        setToolbarWidth(sWidth)
      }
    }
  }

  const pdfName = document_name

  const transform = (slot, showAIPlugin) => {
    return {
      ...slot,
      Open: () => <></>,
      OpenMenuItem: () => <></>,
      Print: () => <></>,
      PrintMenuItem: () => <></>,
      Download: () => <></>,
      DownloadMenuItem: () => <></>,
      SwitchScrollMode: () => <></>,
      SwitchScrollModeMenuItem: () => <></>
    }
  }

  const renderToolbar = (Toolbar) => {
    const TOOLTIP_OFFSET = { left: 50, top: 0 }
    return (
      <>
        {isAnalytics && (
          <Tooltip
            target={
              <MinimalButton
                onClick={() => {
                  setShowError(!showError)
                }}
              >
                <div style={{ color: showError ? '#6A6A6A' : 'black' }}>
                  <SvgIcon>
                    <Robot />
                  </SvgIcon>
                </div>
              </MinimalButton>
            }
            offset={TOOLTIP_OFFSET}
            position={Position.BottomCenter}
            content={() => `AI Generate ${showError ? 'Off' : 'On'}`}
          />
        )}
        <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
      </>
    )
  }

  const pageNavigationPluginInstance = pageNavigationPlugin()
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: (defaultTabs) => [],
    renderToolbar
  })

  const { jumpToPage, CurrentPageLabel } = pageNavigationPluginInstance
  const setGoToPage = (pageIndex) => {
    if (pageIndex > -1 && pageIndex !== 'new') {
      jumpToPage(pageIndex - 1)
    }
  }

  const searchPluginInstance = searchPlugin()
  const { jumpToMatch, highlight, jumpToNextMatch } = searchPluginInstance

  const handleTextSearch = (keyword) => {
    const searchText = 'Text to search in the PDF'
    const words = keyword.split(' ')
    // Get the first 3 words
    const firstThreeWords = words.slice(0, 3)
    const combinedSentence = firstThreeWords.join(' ')
    console.log(firstThreeWords)
    highlight(combinedSentence)
  }

  const handleTextSearchParagraph = (text) => {
    const words = text.split(' ')
    const initialWords = words.slice(0, 4).join(' ')
    highlight(initialWords)
  }

  const handleShareDialog = (event) => {
    setAnchorEl(event.currentTarget)
    setOpenShareDialog(true)
  }

  const handleCloseShareDialog = () => {
    setShareableLink('')
    setIsCopied(false)
    setOpenShareDialog(false)
    setAnchorEl(null)
  }

  const handleCreateShareLink = async () => {
    setLinkLoading(true)
    const data = {
      rfx_id: PDFData?.id,
      valid_for_days: parseInt(customValue, 10)
    }
    const res = await ShareRfx(data)
    if (res.status === 200) {
      setShareableLink(
        `${process.env.REACT_APP_REDIRECT_URL}share/${res.data.data.id}`
      )
      trackEvent('share_rfx', 'SUCCESS', {}, data)

      setLinkLoading(false)
    }
  }

  const handleCustomValueChange = (event) => {
    setCustomValue(event.target.value)
  }

  const copyToClipboard = () => {
    navigator.clipboard.writeText(shareableLink)
    setIsCopied(true)
  }

  const pdfToolTab = [
    {
      label: <span style={{ display: 'flex' }}>Analysis</span>,
      children: (
        <ComplianceMatrix
          content={analysisReport}
          complianceMatrix={complianceMatrix}
          setComplianceMatrix={setComplianceMatrix}
          id={PDFData.id}
          rfxName={PDFData.document_name}
          setGoToPage={setGoToPage}
          handleTextSearch={handleTextSearch}
          handleTextSearchParagraph={handleTextSearchParagraph}
          shareType={shareType}
          editingDisabled={
            isUserViewer && currentUserName !== PDFData?.created_by_user
          }
        />
      )
    },
    {
      label: <span style={{ display: 'flex' }}>Chat</span>,
      children:
        shareType === 'private' ? (
          <ChatBot
            trackerId={PDFData?.id}
            chatType="rfx"
            textBoxPlaceholder={'Ask some questions about this RFX document'}
          />
        ) : (
          <Error
            errorMessage={'Please Login to access the Chat functionality'}
            refresh={false}
            errorLogo={false}
          />
        )
    }
  ]

  const extraButtonsOnTab = [
    {
      label: <span style={{ display: 'flex' }}>Share</span>,
      icon: <ShareIcon style={{ fontSize: '15px' }} />,
      onClick: handleShareDialog
      // name: 'share'
    }
  ]

  const changeTab = (e) => {
    setActiveTab(e)
  }

  const handleNavigateBack = async () => {
    setShowPDF(false)
  }

  return (
    <>
      {PDFData.status === 'completed' ? (
        <Box
          container
          className={page}
          ref={ref}
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'row',
            width: '100%'
          }}
        >
          {pdfWidth && toolbarWidth ? (
            <>
              <Resizable
                width={pdfWidth}
                axis="x"
                onResize={handleOnResize}
                resizeHandles={['e']}
              >
                <Box
                  sx={{
                    width: pdfWidth - 1 + 'px',
                    height: '100%',
                    borderRight: '1px solid #EEEEEE'
                  }}
                  className={webviewer}
                >
                  <Container>
                    <SectionFixed>
                      <Box className={borderBot}>
                        <div className="flex text-xxs gap-2 items-center p-2 px-4">
                          <p
                            className="m-0 cursor-pointer"
                            onClick={() => handleNavigateBack()}
                          >
                            RFx
                          </p>
                          <span>{'>'}</span>
                          <p className="m-0 text-sm font-medium">{pdfName}</p>
                        </div>
                        <Box className={titleContainer}>
                          {shareType === 'private' && (
                            <Box className={shareFileIcon}>
                              <Popover
                                open={Boolean(anchorEl)}
                                anchorEl={anchorEl}
                                onClose={handleCloseShareDialog}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'right'
                                }}
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'right'
                                }}
                              >
                                <DialogContent style={{}}>
                                  {linkLoading ? (
                                    <Grid
                                      container
                                      alignItems="center"
                                      justifyContent="center"
                                    >
                                      <Loader
                                        loading={linkLoading}
                                        caption={
                                          'creating a custom link just for you!'
                                        }
                                      />
                                    </Grid>
                                  ) : (
                                    <>
                                      {shareableLink === '' ? (
                                        <>
                                          {selectedOption === 'custom' && (
                                            <Box
                                              style={{
                                                fontSize: '18px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                paddingBottom: '10px'
                                              }}
                                            >
                                              Number of days the link should be
                                              valid -
                                              <TextField
                                                label=""
                                                type="number"
                                                value={customValue}
                                                onChange={
                                                  handleCustomValueChange
                                                }
                                                style={{
                                                  marginLeft: '8px',
                                                  marginRight: '8px',
                                                  maxWidth: '80px'
                                                }}
                                                InputProps={{
                                                  inputProps: {
                                                    style: {
                                                      paddingRight: '0px'
                                                    }
                                                  }
                                                }}
                                              />
                                              days
                                            </Box>
                                          )}
                                          <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleCreateShareLink}
                                            style={{ float: 'right' }}
                                          >
                                            Create Link
                                          </Button>
                                        </>
                                      ) : (
                                        <>
                                          <div
                                            style={{
                                              display: 'flex',
                                              alignItems: 'center'
                                            }}
                                          >
                                            <TextField
                                              fullWidth
                                              variant="outlined"
                                              value={shareableLink}
                                              InputProps={{
                                                readOnly: true
                                              }}
                                              style={{ marginRight: '8px' }}
                                            />
                                            <Button
                                              onClick={copyToClipboard}
                                              variant="outlined"
                                              startIcon={<FileCopyIcon />}
                                              style={{}}
                                            >
                                              {isCopied ? 'Copied!' : 'Copy'}
                                            </Button>
                                          </div>
                                        </>
                                      )}
                                    </>
                                  )}
                                </DialogContent>
                              </Popover>
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </SectionFixed>
                    <Section overFlow>
                      <Viewer
                        plugins={[
                          toolbarPluginInstance,
                          defaultLayoutPluginInstance,
                          pageNavigationPluginInstance,
                          searchPluginInstance
                        ]}
                        fileUrl={url}
                        initialPage={pageNo - 1}
                        defaultScale={SpecialZoomLevel.PageFit}
                        onPageChange={(page) => {
                          setPdfCurrentPage(page?.currentPage)
                        }}
                      />
                    </Section>
                  </Container>
                </Box>
              </Resizable>
              <Box
                className={infoContainer}
                sx={{
                  width: toolbarWidth + 'px',
                  height: '100%'
                }}
              >
                <div className="flex items-center gap-2 p-3 border-b-1">
                  {pdfToolTab.map((tab, index) => (
                    <Button2
                      onClick={() => changeTab(index)}
                      key={index}
                      style={{
                        padding: '4px 8px',
                        fontSize: '11px',
                        borderRadius: '6px',
                        border:
                          activeTab === index
                            ? '1px solid var(--grey-400) !important'
                            : '1px solid var(--grey-200) !important',
                        color:
                          activeTab === index
                            ? 'var(--grey-700)'
                            : 'var(--grey-600)',
                        backgroundColor:
                          activeTab === index ? 'var(--grey-50)' : 'transparent'
                      }}
                    >
                      {tab.label}
                    </Button2>
                  ))}

                  {shareType === 'private' && (
                    <Button2
                      primary
                      onClick={handleShareDialog}
                      style={{
                        padding: '7px 8px',
                        fontSize: '10px',
                        borderRadius: '100px',
                        marginLeft: 'auto'
                      }}
                    >
                      <ShareIcon className="size-3" />
                    </Button2>
                  )}
                </div>
                <div
                  style={{
                    height: 'calc(100% - 50px)',
                    overflow: 'auto'
                  }}
                >
                  {pdfToolTab[activeTab].children}
                </div>
              </Box>
            </>
          ) : (
            <></>
          )}
        </Box>
      ) : (
        <Grid container className={page}>
          <Grid item xs md lg className={webviewer}>
            <Container>
              <SectionFixed>
                <Box className={borderBot}>
                  <Box className={titleContainer}>
                    {window?.history?.state && (
                      <Box className={iconButton}>
                        <IconButton onClick={() => setShowPDF(false)}>
                          <SvgIcon className={icon}>
                            <BackImg />
                          </SvgIcon>
                        </IconButton>
                      </Box>
                    )}
                    <Box className={pdfTitle}>{pdfName}</Box>
                  </Box>
                </Box>
              </SectionFixed>
              <Section overFlow>
                <Viewer
                  plugins={[
                    toolbarPluginInstance,
                    defaultLayoutPluginInstance,
                    pageNavigationPluginInstance
                  ]}
                  fileUrl={url}
                  initialPage={pageNo - 1}
                  defaultScale={SpecialZoomLevel.PageFit}
                  onPageChange={(page) => {
                    setPdfCurrentPage(page?.currentPage)
                  }}
                />
              </Section>
            </Container>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default PDFViewer
