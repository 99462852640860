import { makeStyles } from '@mui/styles'
const useStyles = makeStyles((theme) => ({
  card: (props) => ({
    padding: '10px 20px',
    margin: props.result_type === 'narrative' ? '7px 0px' : '15px 20px',
    cursor: !props.disabled && props.isBodyClickable ? 'pointer' : 'default',
    border: '1px solid #0000001f',
    borderRadius: '4px',
    '&:hover': {
      background: '#f4f4f5',
      borderRadius: '5px'
    }
  }),
  dragIndicator: {
    cursor: 'grab',
    display: 'flex',
    alignItems: 'center',
    overflow: 'auto'
  },
  titleName: {
    fontFamily: 'PoppinsRegular',
    fontWeight: 700,
    fontSize: 18,
    '& h1, h2, h3, h4, h5, h6, span, p': {
      fontSize: 18,
      lineHeight: '1.5em',
      margin: '0px'
    }
  },
  footerEnd: (props) => ({
    fontSize: 14,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItem: 'center',
    marginTop: '5px'
    // "& .MuiSvgIcon-root": {
    //   cursor: !props.disabled ? "pointer" : "inherit",
    // },
  }),
  content: {
    fontFamily: 'PoppinsRegular',
    fontWeight: 400,
    fontSize: 14,
    '& img': {
      height: 'auto',
      maxWidth: '100%'
    },
    '& h1, h2, h3, h4, h5, h6, span, p': {
      fontSize: 12
      // lineHeight: '1.5em',
      // margin: '0px'
    }
  },
  expandContentDetails: {
    fontSize: 15,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    color: '#5377dd !important',
    maxWidth: '120px'
  },
  titleContainer: {
    margin: '0px 0px 6px 0px'
  },
  tagsDetail: (props) => ({
    fontFamily: 'PoppinsRegular',
    fontWeight: 300,
    fontSize: 11,
    fontStyle: 'italic',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '10px',
    cursor: !props.disabled && props.isTagClickable ? 'pointer' : 'default'
  }),
  listActionsContainer: {
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'end',
    alignSelf: 'end',
    height: '100%',
    minWidth: '110px'
  },
  tagContainer: {
    marginLeft: '5px'
  },
  tagItems: {
    padding: '2px 2px !important'
  },
  expandCollapseContainer: {
    width: '100%'
  },
  newLineCharaters: {
    whiteSpace: 'pre-line'
  },
  defaultHeight: {
    height: '120px',
    overflow: 'hidden'
  },
  contentDisplay: {
    height: '104px',
    overflow: 'hidden'
  },
  moreContentDisplay: {
    height: '104px',
    overflowY: 'auto'
  },
  titleLink: {
    cursor: 'pointer',
    '&:hover': { textDecoration: 'underline' },
    width: 'fit-content'
  },
  variationWrapper: {
    height: 'auto',
    paddingLeft: '20px',
    paddingRight: '0',
    paddingBottom: '5px',
    overflow: 'hidden',
    paddingTop: '5px',
    margin: '15px 20px'
  },
  typeTag: {
    color: '#797979',
    borderRadius: '5px',
    padding: '3px 8px',
    fontSize: '15px',
    marginTop: '5px',
    cursor: 'pointer',
    fontWeight: 'normal',
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      inset: '0',
      borderRadius: '4px',
      padding: '1px',
      background: 'linear-gradient(100deg, #471CA8, #FF3465)',
      WebkitMask:
        'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
      WebkitMaskComposite: 'xor',
      maskComposite: 'exclude'
    }
  }
}))

export { useStyles }
