import React, { useEffect, useState, useMemo, useRef } from 'react'
import { Grid } from '@material-ui/core'
import {
  useNavigate,
  createSearchParams,
  useLocation,
  useSearchParams,
  useParams
} from 'react-router-dom'
import ListTable from '../../components/ListTable'
import ListFilter from '../../sections/ListFilter'
import _, { isArray, cloneDeep } from 'lodash'
import { Container, Section, SectionFixed } from '../../components/Container'
import Loader from '../../components/Loader'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { ROUTES } from '../../config/routes'
import { useStyles } from './styles'
import EmptyRecord from '../../sections/EmptyRecords'
import { checkUserRoleSuperUser } from '../../utils/User'
import ClientResult from '../../sections/ClientResult'
import { initalizeS3 } from '../../utils/AWS'
import {
  mergeClients,
  getIndividualClient,
  getClearBitData
} from '../../store/api'
import { toast } from 'react-toastify'
import CircularProgress from '@mui/material/CircularProgress'
import { setToasterAlert } from '../../store/Common/Actions'
import trackEvent from '../../utils/TrackEvent/TrackEvent'
import mixpanelEvents from '../../config/mixpanelEvents'
import InfiniteScroll from 'react-infinite-scroll-component'
import fetchClientsList from '../../store/Clients/Actions'
import HeadlessTable from '../../components/TableV2/HeadlessTable'
import SearchNew from '../../sections/ListFilter/SearchNew'
import { ImageAvatarFallback } from '../../components'

const ClientList = (props) => {
  const { showSearch = false, isEditable = true, isSuperUser = false } = props
  const { LIBRARY_CLIENTS_RESUME } = ROUTES
  const isFocused = location.pathname.includes(LIBRARY_CLIENTS_RESUME)

  const clientsList = useSelector((state) => state?.clients?.clientsList)
  const hasMoreData = useSelector((state) => state?.clients?.hasMoreData)

  const { clientId } = useParams()
  const user = useSelector((state) => state.authenticate.user)
  const [loading, setLoading] = useState(true)
  const [rows, setRows] = useState([])
  const [page, setPage] = useState(1)
  const [PDFData, setPDFData] = useState({})
  const dispatch = useDispatch()
  const [showDrawer, setShowDrawer] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [filteredRecord, setFilteredRecord] = useState([])
  const navigate = useNavigate()
  const classes = useStyles({ showDrawer })
  const { grid, tableContainer } = classes
  const [currentClientData, setCurrentClientData] = useState({})
  const [topVariatonList, setTopVariatonList] = useState([])
  const [topProposalScreenshot, setTopProposalScreenshot] = useState([])
  const [topProposalScreenshotOrigin, setTopProposalScreenshotOrigin] =
    useState([])
  const [openClient, setOpenClient] = useState(false)
  const [currentClient, setCurrentClient] = useState(null)
  const [screenshotIndex, setScreenshotIndex] = useState(0)
  const [s3Obj, sets3Obj] = useState(null)
  const [originProposalNames, setOriginProposalNames] = useState([])
  const [isMerge, setIsMerge] = useState(false)
  const [selectedData, setSelectedData] = useState([])
  const [selectedRowData, setSelectedRowData] = useState([])
  const [isSearchReloadRequired, setIsSearchReloadRequired] = useState(false)
  const [singleData, setSingleData] = useState(null)

  const handleCloseClient = () => {
    setOpenClient(false)
    setLoading(true)
    navigate(ROUTES.LIBRARY_CLIENTS_RESUME)
  }

  useEffect(() => {
    async function initalizeData() {
      const s3 = await initalizeS3()
      sets3Obj(s3)
    }
    handleSearch('')
    initalizeData()
  }, [])

  useEffect(() => {
    if (isFocused) {
      if (!clientsList && showSearch) {
        setLoading(true)
        dispatch(
          fetchClientsList(
            {
              page_num: 1,
              page_size: 10,
              keyword: ''
            },
            'fetch',
            ''
          )
        )
      }

      // } else if (!clientsList && clientId) {
      //   if (!singleData) {

      //   } else {
      //     OpenClient(null, null, { id: clientId }, singleData)
      //     setLoading(false)
      //   }
      // }
      // } else if (clientsList) {
      //   if (clientId) {
      //     const dataIndex = clientsList.findIndex(
      //       (item) => item?.id === clientId
      //     )
      //     fetchSingleData()
      //     // if (dataIndex !== -1) {
      //     //   // OpenClient(null, null, { id: clientId })
      //     //   setLoading(false)
      //     // } else {
      //     // }
      //   } else {
      //     setLoading(false)
      //     const hasScroll = checkHorizontalScrollbar('scrollableDivClient')
      //     if (!hasScroll && hasMoreData && !openClient && isFocused) {
      //       fetchMoreData()
      //     }
      //   }
      // }
    }
  }, [clientsList, isFocused, hasMoreData, clientId, singleData])

  useEffect(() => {
    if (clientId) {
      fetchSingleData()
      setOpenClient(true)
    }
  }, [clientId])

  const fetchSingleData = async () => {
    setLoading(true)
    const res = await getIndividualClient({ client_id: clientId })
    if (res.status === 200) {
      const result = res.data
      let client = result.clients

      const { domain_id, proposal_id } = client
      const thumbnail_url = `https://${process.env.REACT_APP_IMAGE_BUCKET}.s3.amazonaws.com/snapshots/${domain_id}/${proposal_id}/original/1.png`

      client = {
        ...client,
        thumbnail_url,
        projects: client?.data?.map((project) => {
          const { proposal_id: project_proposal_id } = project
          const thumbnail_url = `https://${process.env.REACT_APP_IMAGE_BUCKET}.s3.amazonaws.com/snapshots/${domain_id}/${project_proposal_id}/original/1.png`
          return {
            ...project,
            thumbnail_url
          }
        })
      }
      setLoading(false)
      setCurrentClient(client)
      setSingleData(client)
      // if (clientsList?.length > 0) {

      // } else {
      //   toast.error('Unable to fetch clients')
      // }
    } else {
      toast.error('Unable to fetch clients')
    }
  }

  const fetchMoreData = () => {
    const nextPage = page + 1
    setPage(nextPage)
    dispatch(
      fetchClientsList(
        {
          page_num: nextPage,
          page_size: 10,
          keyword: ''
        },
        'fetch',
        ''
      )
    )
  }
  const navigateOpenClient = (i, row) => {
    const clientId = row?.id
    navigate('/library/clients/' + clientId)
  }

  const headCells = [
    {
      id: 'documentName',
      disablePadding: true,
      label: 'Client Name',
      sort: false,
      width: '35%',
      accessorKey: 'documentName'
    },
    {
      id: 'documentLocation',
      disablePadding: true,
      label: 'Location',
      sort: false,
      width: '35%',
      accessorKey: 'location'
    },
    {
      id: 'projectName',
      disablePadding: true,
      label: 'Project',
      sort: false,
      width: '65%',
      accessorKey: 'projectName'
    }
  ]

  const columns = [
    {
      id: 'documentName',
      label: 'Client Name',
      accessorKey: 'documentName',
      children: (row) => {
        return (
          <span className="flex items-center gap-2">
            <ImageAvatarFallback
              name={row?.documentName}
              profilePic={row?.logo}
              style={{
                fontSize: '12px',
                background: 'var(--grey-700)',
                height: '30px',
                width: '30px'
              }}
              openImage={() => {}}
              s3Obj={s3Obj}
            />
            {row?.documentName}
          </span>
        )
      },
      style: {
        fontFamily: 'PoppinsMedium',
        fontSize: '14px'
      }
    },
    {
      id: 'domain',
      label: 'Domain',
      accessorKey: 'domain'
    },
    {
      id: 'projectName',
      label: 'Project',
      accessorKey: 'title'
    },
    {
      id: 'documentLocation',
      label: 'Location',
      accessorKey: 'location',
      style: {
        fontStyle: 'italic',
        fontSize: '10px'
      }
    }
  ]

  const setInitialData = () => {
    const data = []
    clientsList &&
      clientsList.forEach((client) => {
        const el = {}
        el.id = client?.id
        el.documentName = client?.title
        el.projectName = client?.data?.project_name
        el.location = client?.data?.client_location
        el.logo = client?.enrichment_data?.logo
        el.domain = client?.enrichment_data?.domain
        data.push(el)
      })

    setRows(data)
  }

  useEffect(() => {
    if (isArray(clientsList) && clientsList.length > 0) {
      setInitialData()
      setLoading(false)
      setFilteredRecord(clientsList)
    }
  }, [clientsList])

  useEffect(() => {
    if (!_.isEmpty(PDFData) && PDFData?.showDrawer) {
      setShowDrawer(true)
    }
  }, [PDFData])

  // const OpenClient = (e, i, row, list = clientsList) => {
  //   if (!list) {
  //     return
  //   }
  //   const clientId = row?.id
  //   const filteredJson = list?.filter((item) => item?.id === clientId)
  //   const thumbnail_url = filteredJson[0]?.thumbnail_url
  //   const thumbnail_url_list = filteredJson[0]?.variation_list?.map(
  //     (item) => item?.thumbnail_url
  //   )
  //   setCurrentClient(filteredJson[0])
  //   setTopProposalScreenshot([thumbnail_url, ...thumbnail_url_list])
  //   const thumbnail_url_origin =
  //     filteredJson[0]?.page_number === 1
  //       ? filteredJson[0]?.proposal_url.includes('rfx')
  //         ? 'rfx'
  //         : 'proposal'
  //       : 'resume'
  //   const thumbnail_url_list_origin = filteredJson[0]?.variation_list?.map(
  //     (item) =>
  //       item?.page_number === 1
  //         ? item?.proposal_url.includes('rfx')
  //           ? 'rfx'
  //           : 'proposal'
  //         : 'resume'
  //   )
  //   setTopProposalScreenshotOrigin([
  //     thumbnail_url_origin,
  //     ...thumbnail_url_list_origin
  //   ])
  //   setOriginProposalNames([
  //     filteredJson[0]?.proposal_name,
  //     ...filteredJson[0]?.variation_list?.map((item) => item?.proposal_name)
  //   ])
  //   setCurrentClientData({
  //     ...filteredJson[0]?.data,
  //     proposal_name: filteredJson[0]?.proposal_name,
  //     client_group_id: filteredJson[0]?.id,
  //     client_id: filteredJson[0]?.client_id
  //   })
  //   setTopVariatonList(filteredJson[0]?.variation_list)
  //   setScreenshotIndex(0)
  // }

  // const handleVariationIndexChange = (index) => {
  //   if (index >= 0) {
  //     setScreenshotIndex(index + 1)
  //     // setCurrentClientData(topVariatonList?.[index]?.data);
  //     setCurrentClientData({
  //       ...topVariatonList?.[index]?.data,
  //       proposal_name: topVariatonList?.[index]?.proposal_name
  //     })
  //     // setTopVariatonList([]);
  //   } else {
  //     setScreenshotIndex(0)
  //     // setCurrentClientData(currentClient?.data);
  //     setCurrentClientData({
  //       ...currentClient?.data,
  //       proposal_name: currentClient?.proposal_name
  //     })
  //     setTopVariatonList(currentClient?.variation_list)
  //   }
  // }

  const handleSearch = (key) => {
    setSearchValue(key)
  }

  const handleSearchQuery = (event, value = null) => {
    if (event?.keyCode === 13) {
      trackEvent(
        'CLIENT_SEARCHED',
        'SUCCESS',
        {},
        { search_value: value || searchValue }
      )
      setIsSearchReloadRequired(true)
      setLoading(true)
      setPage(1)
      dispatch(
        fetchClientsList(
          {
            page_num: 1,
            page_size: 10,
            keyword: value || searchValue
          },
          'fetch',
          ''
        )
      )
    }
  }

  // const handlePDFViewer = async (index) => {
  //   const pageNoList = [
  //     currentClient?.page_number,
  //     ...currentClient?.variation_list?.map((item) => item?.page_number)
  //   ]
  //   const proposalIdList = [
  //     currentClient?.proposal_id,
  //     ...currentClient?.variation_list?.map((item) => item?.proposal_id)
  //   ]
  //   const newPath = `/view/pdf/${proposalIdList?.[index]}/1`
  //   window.open(newPath, '_blank')
  // }

  const handleMerge = async () => {
    if (isMerge === false) {
      setIsMerge(true)
    } else {
      if (selectedData.length > 1) {
        setIsMerge('loading')
        const toastId = toast.info(
          <div style={{ display: 'flex' }}>
            {'Merging Clients'}&nbsp;
            <CircularProgress size={20} />
          </div>,
          {
            autoClose: false,
            closeOnClick: false,
            closeButton: false,
            draggable: false
          }
        )
        const req = {
          collection_list: selectedData
        }
        const res = await mergeClients(req)
        if (res?.status === 200) {
          // trackEvent(mixpanelEvents["PROJECTS_MERGED"], "SUCCESS", {}, { project_name: selectedRowData?.map(item => item?.data?.project_name).join(", ") })
          setIsMerge(false)
          setSelectedData([])
          setSelectedRowData([])
          toast.dismiss(toastId)
          dispatch(
            setToasterAlert({
              message: 'Clients merged successfully!',
              show: true,
              type: 'success'
            })
          )
          setLoading(true)
          dispatch(
            fetchClientsList(
              {
                page_num: 1,
                page_size: 10,
                keyword: ''
              },
              'fetch',
              ''
            )
          )
        } else {
          // trackEvent(mixpanelEvents["PROJECTS_MERGED"], "FAILED", {}, { project_name: selectedRowData?.map(item => item?.data?.project_name).join(", ") })
          toast.dismiss(toastId)
          setIsMerge(false)
          setSelectedData([])
          setSelectedRowData([])
          dispatch(
            setToasterAlert({
              message: 'Error while merging clients...',
              show: true,
              type: 'error'
            })
          )
        }
      } else {
        dispatch(
          setToasterAlert({
            message: 'Please select atleast 2 clients to merge',
            show: true,
            type: 'error'
          })
        )
      }
    }
  }

  let extraButtons = []

  if (isSuperUser) {
    extraButtons = [
      {
        text: 'clear',
        condition: searchValue !== '',
        onClick: () => {
          setSearchValue('')
          if (isSearchReloadRequired) {
            setIsSearchReloadRequired(false)
            setLoading(true)
            setPage(1)
          }
        },
        icon: '',
        tooltip: 'clear'
      },
      // {
      //   text: `${
      //     isMerge
      //       ? isMerge === 'loading'
      //         ? 'Merging...'
      //         : 'Merge Selected Clients'
      //       : 'Merge'
      //   } `,
      //   condition: true,
      //   onClick: handleMerge,
      //   icon: '',
      //   tooltip: `${
      //     isMerge
      //       ? isMerge === 'loading'
      //         ? 'Merging...'
      //         : 'Merge Selected Clients'
      //       : 'Merge'
      //   } `
      // },
      {
        text: 'cancel',
        condition: isMerge === true,
        onClick: () => {
          setIsMerge(false)
          setSelectedData([])
          setSelectedRowData([])
        },
        icon: '',
        tooltip: 'cancel'
      }
    ]
  }

  const checkHorizontalScrollbar = (target) => {
    const element = document.getElementById(target)
    return element ? element.scrollHeight !== element.clientHeight : false
  }

  if (loading) {
    return (
      <Container>
        <SectionFixed>
          <ListFilter
            isShowFilter={false}
            filterData={[]}
            filter={() => {}}
            resetFilter={() => {}}
            searchValue={searchValue}
            handleSearch={handleSearch}
            searchOnEnter={handleSearchQuery}
            selectedFilter={[]}
            setSelectedFilter={() => {}}
            pageType="library"
            isEditable={false}
          />
        </SectionFixed>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          className={grid}
        >
          <Loader loading={loading} caption={'loading your clients'} />
        </Grid>
      </Container>
    )
  }

  if (clientId && currentClient) {
    return (
      <ClientResult
        currentClient={currentClient}
        data={currentClientData}
        openProject={openClient}
        handleCloseProject={handleCloseClient}
        s3Obj={s3Obj}
        openType={'tab'}
        thumbnail_url_list={topProposalScreenshot}
        refresh={fetchSingleData}
        screenshotIndex={screenshotIndex}
        originProposalNames={originProposalNames}
        // handlePDFViewer={handlePDFViewer}
        topProposalScreenshotOrigin={topProposalScreenshotOrigin}
        topVariatonList={topVariatonList}
        setTopVariatonList={setTopVariatonList}
        singleData={singleData}
        setSingleData={setSingleData}
      />
    )
  }

  return (
    <Container>
      {showSearch && !singleData && (
        <SectionFixed>
          {showSearch && (
            <div
              className="flex justify-end items-end"
              style={{
                padding: '10px 18px'
              }}
            >
              <div className="flex gap-2 items-center">
                <SearchNew
                  value={searchValue}
                  onChange={(value) => handleSearch(value)}
                  onEnter={(value) => {
                    handleSearch(value)
                    handleSearchQuery({ keyCode: 13 }, value)
                  }}
                />
              </div>
            </div>
          )}
        </SectionFixed>
      )}
      <Section overFlow>
        {clientsList?.length > 0 || singleData ? (
          <div className={tableContainer}>
            {openClient ? (
              <></>
            ) : (
              <>
                <HeadlessTable
                  leftAlign={true}
                  columns={columns}
                  data={rows}
                  loading={loading}
                  onRowClick={(row) => {
                    navigateOpenClient(null, row)
                  }}
                />
                {/* <div
                  id="scrollableDivClient"
                  style={{ height: '100%', overflow: 'auto' }}
                >
                  <InfiniteScroll
                    dataLength={rows?.length ? rows.length : 0}
                    next={() => fetchMoreData()}
                    hasMore={hasMoreData}
                    loader={
                      !openClient && <Loader loading={true} caption={''} />
                    }
                    scrollableTarget="scrollableDivClient"
                  >
                    <ListTable
                      page={page}
                      setPage={setPage}
                      rows={rows}
                      headCells={headCells}
                      onClickFile={navigateOpenClient}
                      onClickFileKey={['documentName']}
                      isEditable={false}
                      pageType={'project-list'}
                      rowAction={rowAction}
                      multiSelect={isMerge}
                      rowSelection={rowSelection}
                      selectedRows={selectedData}
                      isSortActive={false}
                      paginate={false}
                    />
                  </InfiniteScroll>
                </div> */}
              </>
            )}
          </div>
        ) : (
          <EmptyRecord displayMessage={'No clients found'} />
        )}
      </Section>
    </Container>
  )
}

export default ClientList
