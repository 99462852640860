import { makeStyles } from '@mui/styles'
const useStyles = makeStyles({
  displayPageName: {
    fontFamily: 'PoppinsRegular',
    fontSize: '14px',
    color: 'grey',
    lineHeight: '21px',
    letterSpacing: '0.01em',
    margin: '0px',
    fontWeight: '600'
  },
  hiddenRootContainer: {
    overflow: 'auto',
    width: '100%',
    borderBottom: '1px solid rgb(220 215 215)',
    '& button': {
      display: 'none'
    },
    '&:hover': {
      background: '#eeeee6',
      borderRadius: '5px',
      // margin: "2px 0",
      '& button': {
        display: 'flex',
        alignItems: 'center',
        alignSelf: 'center'
      }
    }
  },
  visibleRootContainer: (props) => ({
    // padding: '8px',
    overflow: 'auto',
    border: '1px solid var(--grey-200)',
    margin: '12px 0px',
    minHeight: '131px',
    borderRadius: '5px'
    // '&:hover': {
    //   background: 'var(--grey-50)'
    //   // margin: "10px 0px"
    // }
  }),
  favoriteIcon: {
    color: 'red'
  },
  copyButton: {
    width: '20px',
    height: '18px',
    color: '#797979'
  },
  narrativeContainer: {
    width: '100%',
    padding: '10px 20px',
    '& button': {
      display: 'none'
    },
    '&:hover': {
      background: '#eeeee6',
      borderRadius: '5px',
      '& button': {
        display: 'block'
      }
    }
  },

  buttonLabel: {
    fontWeight: '700',
    color: '#949494',
    fontSize: '16px',
    margin: '0px !important',
    paddingTop: 0,
    paddingLeft: '5px'
  },
  displayContent: {
    fontWeight: '500',
    color: '#000000',
    padding: 0,
    marginBottom: 0,
    '& h1, h2, h3, h4, h5, h6, span, p': {
      lineHeight: '1.5em',
      margin: '0px'
    }
  },
  resultTitle: {
    cursor: 'pointer',
    marginTop: '0px',
    marginBottom: '2px',
    padding: 0,
    fontFamily: 'PoppinsRegular',
    fontWeight: 700,
    fontSize: 15,
    '& h1, h2, h3, h4, h5, h6, span, p': {
      fontSize: 15,
      lineHeight: '1.5em',
      margin: '0px'
    },
    '&:hover': { textDecoration: 'underline' }
  },
  dragIndicator: {
    cursor: 'grab',
    display: 'flex',
    alignItems: 'center',
    overflow: 'auto'
  },
  variationsContainer: {
    padding: '100px',
    zIndex: 1,
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center'
  },
  pdfTitleContainer: {
    margin: '15px 0px'
  },
  iconButton: {
    float: 'left'
  },
  loaderContainer: {
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    height: '100%'
  },
  pdfTitle: {
    p: 1,
    display: 'flex',
    justifyContent: 'center'
  },
  icon: {
    height: '16px',
    width: '16px'
  },
  cardAction: {
    display: 'flex ',
    alignItems: 'center'
  },
  variationWrapper: {
    height: 'auto',
    background: 'white',
    paddingTop: '5px',
    paddingRight: '0',
    paddingBottom: '5px',
    overflow: 'hidden'
  },
  activeContainer: {
    background: '#eeeee6',
    borderRadius: '5px'
  },
  imageContainer: {
    alignItems: 'center',
    display: 'flex',
    overflow: 'auto',
    height: '525px'
  },
  imageWrapper: {
    width: '400px',
    maxHeight: '500px',
    objectFit: 'contain',
    border: '1px solid #E5E5E5',
    cursor: 'pointer',
    margin: '5px'
  },
  similarImage: {
    width: '20px !important',
    height: '20px !important',
    paddingRight: '5px'
  },
  typeTag: {
    color: '#797979',
    borderRadius: '5px',
    padding: '3px 8px',
    fontSize: '15px',
    marginTop: '5px',
    cursor: 'pointer',
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      inset: '0',
      borderRadius: '4px',
      padding: '1px',
      background: 'linear-gradient(100deg, #471CA8, #FF3465)',
      WebkitMask:
        'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
      WebkitMaskComposite: 'xor',
      maskComposite: 'exclude'
    }
  }
})

export { useStyles }
