import { getSignedUrlWithoutS3 } from '../../../../utils/AWS'
import { saveAs } from 'file-saver'
const ENDPOINT_PATTERN = /^(.+\.)?s3[.-]([a-z0-9-]+)\./
export const exportToDoc = async (
  element,
  filename = '',
  setShowToast,
  setSpinnerProgress
) => {
  setSpinnerProgress(10)
  const docElement = element.toString().replace('htmlString ', '')
  let html = docElement
  const regexp = /(?<=<img src=")(?!data:)(.*?)(?=\")/g
  const array = [...html.matchAll(regexp)]
  let i = 1
  await Promise.all(
    array.map(async (url) => {
      const oldUrl = url[0]
      let newBase64 = ''
      if (ENDPOINT_PATTERN.test(oldUrl)) {
        const newUrl = await getSignedUrlWithoutS3(oldUrl)
        newBase64 = await getBase64FromUrl(newUrl)
      } else {
        newBase64 = await getBase64FromUrl(oldUrl)
      }
      html = html?.replace(oldUrl, newBase64)
      const progress = 10 + (i / array.length) * 80
      i = i + 1
      setSpinnerProgress(progress)
    })
  )
  import('html-docx-js/dist/html-docx').then((htmlDocx) => {
    const docxContent = htmlDocx.asBlob(html)
    filename = filename ? filename + '.docx' : 'document.docx'
    saveAs(docxContent, filename)
    setSpinnerProgress(100)
    setShowToast(false)
  })
}

export const exportToHTML = async (
  element,
  filename = '',
  setShowToast,
  setSpinnerProgress
) => {
  setSpinnerProgress(10)
  const docElement = element.toString().replace('htmlString ', '')
  const HtmlHead = '<html></head><body>'
  const EndHtml = '</body></html>'
  let html = HtmlHead + docElement + EndHtml
  const regexp = /(?<=<img src=")(.*?)(?=\")/g
  const array = [...html.matchAll(regexp)]
  let i = 1
  await Promise.all(
    array.map(async (url) => {
      const oldUrl = url[0]
      if (['amazonaws'].includes(url[0])) {
        const newUrl = await getSignedUrlWithoutS3(oldUrl)
        const newBase64 = await getBase64FromUrl(newUrl)
        html = html?.replace(oldUrl, newBase64)
      }
      const progress = 10 + (i / array.length) * 80
      i = i + 1
      setSpinnerProgress(progress)
    })
  )
  element = document.createElement('a')
  element.setAttribute(
    'href',
    'data:text/html;charset=utf-8,' + encodeURIComponent(html)
  )
  filename = filename ? filename + '.html' : 'index.html'
  element.setAttribute('download', filename)
  element.style.display = 'none'
  document.body.appendChild(element)
  element.click()
  document.body.removeChild(element)
  setSpinnerProgress(100)
  setShowToast(false)
}

export const getBase64FromUrl = (url) => {
  return new Promise((resolve) => {
    fetch(url)
      .then((data) => data.blob())
      .then((blob) => {
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onloadend = () => {
          const base64data = reader.result
          resolve(base64data)
        }
      })
  })
}
