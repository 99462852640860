export const ROUTES = {
  LANDING: '/',
  DOMAIN: '/domain',
  LIBRARY: '/library/:currentTab',
  LIBRARY_KNOWLEDGE: '/library/knowledge',
  LIBRARY_PROJECTS: '/library/projects',
  LIBRARY_PEOPLE_RESUME: '/library/people-resume',
  LIBRARY_INDIVIDUAL_MASTER_PEOPLE_RESUME:
    '/library/people-resume/:masterResumeId',
  LIBRARY_INDIVIDUAL_PEOPLE_RESUME:
    '/library/people-resume/:masterResumeId/:resumeId',
  LIBRARY_PROJECTS_VIEW: '/library/projects/:collectionId',
  LIBRARY_PROJECTS_VARIATION_VIEW:
    '/library/projects/:collectionId/:variationId',
  LIBRARY_CLIENTS_RESUME: '/library/clients',
  LIBRARY_CLIENT_RESUME_VIEW: '/library/clients/:clientId',
  FILES: '/files',
  SETTINGS_PREFIX: '/settings',
  SETTINGS: '/settings/:currentTab',
  SETTINGS_PROFILE: '/settings/profile',
  SETTINGS_DOMAIN: '/settings/domain',
  SETTINGS_USERS: '/settings/users',
  SETTINGS_CHAT: '/settings/chat',
  SETTINGS_TAGCENTER: '/settings/tagcenter',
  SETTINGS_CHANGEDOMAIN: '/settings/changedomain',
  SETTINGS_ADMIN: '/settings/admin',
  SETTINGS_CREATEDOMAIN: '/settings/createdomain',
  SETTINGS_IDENTITYPROVIDERS: '/settings/idp',
  SETTINGS_STYLEGUIDE: '/settings/styleguide',
  PROPOSALS: '/proposals',
  ANALYTICS: '/analytics',
  ANALYTICS_PDF_VIEWER: '/analytic/pdf/:analyticId/:type',
  RFX_PDF_VIEWER: '/rfx/pdf/:rfxId/:pageNo',
  PDF_VIEWER: '/view/pdf/:fileId/:pageNo',
  EDITOR_PDF_VIEWER: '/view/pdf/:fileId/:pageNo/:openAnnotator',
  DASHBOARD: '/dashboard',
  DASHBOARD_RESULT: '/dashboard/result',
  TEMP_PDF_EDITOR: '/tempPdf',
  SIGNUP: '/auth?state=signup',
  SIGNIN: '/auth',
  ANALYTICS_HOME: '/analytics-home/:currentTab',
  ANALYTICS_HOME_ANALYTICS: '/analytics-home/analytics',
  ANALYTICS_HOME_STYLE_GUIDE: '/analytics-home/styleguide',
  ANALYTICS_HOME_RFX: '/analytics-home/rfx',
  CHAT: '/chat/:sessionId',
  SESSION_CHAT_FEEDBACK: '/view-chat/:sessionId',
  SHARE_RFX_VIEW: '/share/:shareId',
  SHARE_ANALYTICS_VIEW: '/share-analytics/:shareId',
  WORKSPACE: '/workspace',
  WORKSPACE_HOME: '/workspace/:workspaceId',
  WORKSPACE_DRAFT: '/workspace/:workspaceId/:draftId'
}
