import {
  FETCH_TAGS,
  UPDATE_KEY_TAG,
  UPDATE_VALUE_MULTI_TAG,
  UPDATE_VALUE_TAG,
  UPDATE_MULTI_VALUE_TAG,
  UPDATE_NEW_VALUE_TAG
} from '../../actionTypes'
import _ from 'lodash'
const initialState = {
  tags: null
}

function tagReducer(state = initialState, action) {
  const { type, payload } = action
  const sortHelper = (a, b) => {
    if (a.value === null || a.value === undefined) return 1
    if (b.value === null || b.value === undefined) return -1
    return a.value.toLowerCase().localeCompare(b.value.toLowerCase())
  }
  switch (type) {
    case FETCH_TAGS: {
      return {
        ...state,
        tags: payload
      }
    }
    case UPDATE_VALUE_MULTI_TAG: {
      const { tags, status } = payload
      const newTags = _.cloneDeep(state.tags)
      if (status === 'create') {
        tags.forEach((tag) => {
          if (newTags && newTags[tag.key]) {
            if (!newTags[tag.key].data) {
              newTags[tag.key].data = []
            }
            newTags[tag.key].data.push(tag)
          }
        })
      } else if (status === 'delete') {
        tags.forEach((tag) => {
          const index = _.findIndex(newTags[tag.key].data, { id: tag.id })
          if (index > -1) {
            newTags[tag.key].data.splice(index, 1)
          }
        })
      } else if (status === 'statusUpdate') {
        tags.forEach((tag) => {
          const index = _.findIndex(newTags[tag.key].data, { id: tag.id })
          if (index > -1) {
            newTags[tag.key].data[index] = {
              ...newTags[tag.key].data[index],
              synced: true
            }
          }
        })
      }
      return {
        ...state,
        tags: newTags
      }
    }
    case UPDATE_KEY_TAG: {
      const { status, synced, type, key_type, ...restObj } = payload
      if (status === 'create') {
        const newTags = _.cloneDeep(state.tags)
        newTags[restObj.key] = {
          data: [payload],
          synced,
          key_type,
          type
        }
        return {
          ...state,
          tags: newTags
        }
      } else if (status === 'update') {
        const newTags = _.cloneDeep(state.tags)
        const { key: newKey, oldKey } = restObj
        const updatedTags = Object.fromEntries(
          Object.entries(newTags).map(([key, value]) => {
            if (key === oldKey) {
              return [
                newKey,
                {
                  ...value,
                  synced,
                  type,
                  key_type,
                  data: value.data.map((item) => ({
                    ...item,
                    key: newKey,
                    key_type,
                    type
                  }))
                }
              ]
            }
            return [key, value]
          })
        )

        return {
          ...state,
          tags: updatedTags
        }
      } else if (status === 'statusUpdate') {
        const newTags = _.cloneDeep(state.tags)
        newTags[restObj.key] = {
          ...newTags[restObj.key],
          synced,
          type
        }
        return {
          ...state,
          tags: newTags
        }
      } else if (status === 'delete') {
        if (synced) {
          const newTags = _.cloneDeep(state.tags)
          delete newTags[restObj.key]
          return {
            ...state,
            tags: newTags
          }
        } else {
          const newTags = _.cloneDeep(state.tags)
          newTags[restObj.key] = {
            ...newTags[restObj.key],
            synced,
            type
          }
          return {
            ...state,
            tags: newTags
          }
        }
      }
      return {
        ...state,
        tags: payload
      }
    }
    case UPDATE_VALUE_TAG: {
      const { status, synced, type, ...restObj } = payload
      if (status === 'create') {
        const newTags = _.cloneDeep(state.tags)
        if (newTags[restObj.key]?.data) {
          newTags[restObj.key] = {
            ...newTags[restObj.key],
            data: [...newTags[restObj.key].data, payload]
          }
        } else {
          newTags[restObj.key] = {
            data: [payload],
            synced,
            type
          }
        }
        try {
          newTags[restObj.key].data.sort(sortHelper)
        } catch (e) {
          console.log(e)
        }
        return {
          ...state,
          tags: newTags
        }
      } else if (status === 'statusUpdate') {
        const newTags = _.cloneDeep(state.tags)
        const { id } = payload
        const index = _.findIndex(newTags[restObj.key].data, { id })
        if (index > -1) {
          newTags[restObj.key].data[index] = {
            ...newTags[restObj.key].data[index],
            ...payload
          }
        }
        try {
          newTags[restObj.key].data.sort(sortHelper)
        } catch (e) {
          console.log(e)
        }
        return {
          ...state,
          tags: newTags
        }
      } else if (status === 'update') {
        const newTags = _.cloneDeep(state.tags)
        const { id, value } = payload
        const index = _.findIndex(newTags[restObj.key].data, { id })
        if (index > -1) {
          newTags[restObj.key].data[index] = {
            ...newTags[restObj.key].data[index],
            value,
            synced,
            type
          }
        }
        try {
          newTags[restObj.key].data.sort(sortHelper)
        } catch (e) {
          console.log(e)
        }
        return {
          ...state,
          tags: newTags
        }
      } else if (status === 'delete') {
        if (synced) {
          const newTags = _.cloneDeep(state.tags)
          const { id } = payload
          const index = _.findIndex(newTags[restObj.key].data, { id })
          if (index > -1) {
            newTags[restObj.key].data.splice(index, 1)
          }
          return {
            ...state,
            tags: newTags
          }
        } else {
          const newTags = _.cloneDeep(state.tags)
          const { id } = payload
          const index = _.findIndex(newTags[restObj.key].data, { id })
          if (index > -1) {
            newTags[restObj.key].data[index] = {
              ...newTags[restObj.key].data[index],
              ...payload
            }
          }
          try {
            newTags[restObj.key].data.sort(sortHelper)
          } catch (e) {
            console.log(e)
          }
          return {
            ...state,
            tags: newTags
          }
        }
      }
      return {
        ...state
      }
    }
    case UPDATE_MULTI_VALUE_TAG: {
      const { tags, status, primary_tag_id = '', synced, key } = payload
      const primaryTagIndex = _.findIndex(state.tags[key].data, {
        id: primary_tag_id
      })
      if (synced) {
        if (status === 'merge') {
          const newTags = _.cloneDeep(state.tags)
          tags.forEach((tag) => {
            const index = _.findIndex(newTags[key].data, { id: tag.id })
            if (index > -1) {
              newTags[key].data.splice(index, 1)
            }
          })
          const currentPrimaryTagIndex = _.findIndex(newTags[key].data, {
            id: primary_tag_id
          })

          newTags[key].data[currentPrimaryTagIndex] = {
            ...newTags[key].data[currentPrimaryTagIndex],
            synced
          }
          return {
            ...state,
            tags: newTags
          }
        } else if (status === 'delete') {
          const newTags = _.cloneDeep(state.tags)
          tags.forEach((tag) => {
            const index = _.findIndex(newTags[key].data, { id: tag.id })
            if (index > -1) {
              newTags[key].data.splice(index, 1)
            }
          })
          return {
            ...state,
            tags: newTags
          }
        } else {
          const newTags = _.cloneDeep(state.tags)
          tags.forEach((tag) => {
            const index = _.findIndex(newTags[key].data, { id: tag.id })
            if (index > -1) {
              newTags[key].data[index] = {
                ...newTags[key].data[index],
                synced
              }
            }
          })
          if (primaryTagIndex > -1) {
            newTags[key].data[primaryTagIndex] = {
              ...newTags[key].data[primaryTagIndex],
              synced
            }
          }
          return {
            ...state,
            tags: newTags
          }
        }
      } else {
        const newTags = _.cloneDeep(state.tags)
        tags.forEach((tag) => {
          const index = _.findIndex(newTags[key].data, { id: tag.id })
          if (index > -1) {
            newTags[key].data[index] = {
              ...newTags[key].data[index],
              synced
            }
          }
        })
        if (primaryTagIndex > -1) {
          newTags[key].data[primaryTagIndex] = {
            ...newTags[key].data[primaryTagIndex],
            synced
          }
        }
        return {
          ...state,
          tags: newTags
        }
      }
    }

    case UPDATE_NEW_VALUE_TAG: {
      const { new_tags = [], key } = payload
      const newTags = _.cloneDeep(state.tags)
      new_tags.forEach((element) => {
        if (newTags && newTags[key]) {
          if (!newTags[key].data) {
            newTags[key].data = []
          }
          newTags[key].data.push(element)
        }
      })
      return {
        ...state,
        tags: newTags
      }
    }
    default:
      return state
  }
}

export default tagReducer
