import React, { useEffect, useState } from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import { useStyles } from './styles'
import { Container, Section, SectionFixed } from '../Container'
import { Button } from '@mui/material'
import { useColor } from '../../ThemeContext'

export function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

export function TabPanel(props) {
  const { children, value, index, ...other } = props
  const classes = useStyles()
  const { tabpanel, tabSpacing } = classes
  return (
    <div
      role="tabpanel1"
      className={tabSpacing}
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className={tabpanel} id="Tab-data">
          {children}
        </Box>
      )}
    </div>
  )
}

export default function MaterialTabs(props) {
  const {
    data = [],
    isFlex = false,
    activeTab = 0,
    tabChangeCallBack,
    sectionOverFlow = false,
    disabled = false,
    extraButtons = [],
    extraContent = <></>
  } = props

  const classes = props.classes ? props.classes : useStyles()
  const { tabWrapper, root, tab, tabSpacing } = classes
  const [value, setValue] = useState(activeTab)
  const { selectedColor } = useColor()

  const handleChange = (event, newValue) => {
    setValue(newValue)
    if (tabChangeCallBack) {
      tabChangeCallBack(newValue)
    }
  }

  useEffect(() => {
    setValue(activeTab)
  }, [activeTab])

  return isFlex ? (
    <Container>
      <SectionFixed id="inner-tab-root" className={tabWrapper}>
        <Tabs
          className={root}
          value={value || 0}
          onChange={handleChange}
          variant={sectionOverFlow ? 'scrollable' : 'standard'}
          scrollButtons={false}
        >
          {data.map((data, index) => (
            <Tab
              sx={{
                '& .MuiTabs-indicatorSpan': {
                  maxWidth: 40,
                  width: '100%',
                  backgroundColor: '#635ee7'
                }
              }}
              html
              onMouseDown={(event) => handleChange(event, index)}
              disableRipple
              className={tab}
              key={index}
              label={data.label}
              {...a11yProps(index)}
              disabled={disabled}
            />
          ))}
        </Tabs>
        {extraContent}
        {extraButtons.map((button, index) => (
          <Button
            key={index}
            variant="contained"
            startIcon={button?.icon}
            sx={{
              fontSize: '13px',
              maxHeight: '35px',
              margin: '10px',
              borderRadius: '10px',
              backgroundColor: selectedColor
            }}
            onClick={button?.onClick}
          >
            {button?.label}
          </Button>
        ))}
      </SectionFixed>
      <Section overFlow={sectionOverFlow}>
        <Container>
          {data.map((data, index) => (
            <Section
              overFlow={sectionOverFlow}
              key={index}
              role="tabpanel2"
              hidden={value !== index}
              className={tabSpacing}
            >
              {data.children}
            </Section>
          ))}
        </Container>
      </Section>
    </Container>
  ) : (
    <>
      <Box className={tabWrapper}>
        <Tabs
          className={root}
          value={value}
          onChange={handleChange}
          variant={sectionOverFlow ? 'scrollable' : 'standard'}
          scrollButtons={false}
        >
          {data.map((data, index) => (
            <Tab
              onMouseDown={(event) => handleChange(event, index)}
              disableRipple
              className={tab}
              key={index}
              label={data.label}
              {...a11yProps(index)}
              disabled={disabled}
            />
          ))}
        </Tabs>
      </Box>
      {data.map((data, index) => (
        <Box
          key={index}
          role="tabpanel3"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
        >
          {data.children}
        </Box>
      ))}
    </>
  )
}
