import React from 'react'
import Loader from '../Loader'

const HeadlessTable = ({
  columns,
  data,
  loading,
  pageLoading,
  onRowClick,
  leftAlign
}) => {
  return (
    <div className="border-grey-200 rounded-lg relative">
      <div
        className={`flex gap-2 p-2 px-4 bg-grey-100 text-xs sticky top-0 z-10 font-medium border-b-1 ${
          leftAlign ? 'text-left' : 'text-center'
        } `}
      >
        {columns.map((column, index) => {
          return (
            <div className="w-full" key={index}>
              {column.label}
            </div>
          )
        })}
      </div>
      {loading ? (
        <div className="grid place-content-center " style={{ height: '50vh' }}>
          Loading Data... <Loader />
        </div>
      ) : data?.length > 0 ? (
        <div className="grid text-center" id="rows">
          {data?.map((row, index) => {
            return (
              <div
                className={`flex gap-2 px-4 text-center text-sm border-b-1 p-2 row text-grey-600 ${
                  row?.checked ? 'bg-grey-100' : ''
                }
                ${leftAlign ? 'text-left' : 'text-center'}
                `}
                key={index}
                onClick={() => onRowClick(row)}
              >
                {/* <div className="font-medium w-full flex items-center gap-2 text-capitalize">
                  <div
                    className="flex items-center h-full cursor-pointer py-1"
                    onClick={(e) => {
                      e.stopPropagation()
                      handleSetSelectedPeople(id)
                    }}
                  >
                    <input
                      type="checkbox"
                      className={`cursor-pointer checkbox-ui border-grey-200 rounded-md w-3 h-3  ${
                        checked ? 'bg-grey-700' : ''
                      }`}
                      checked={checked}

                      // onChange={(e) => {
                      //   e.stopPropagation()
                      // }}
                    />
                  </div>

                  <ImageAvatarFallback
                    name={name}
                    profilePic={profile_picture_location}
                    style={{
                      height: '30px',
                      width: '30px',
                      marginLeft: 2
                    }}
                    openImage={() => {}}
                    s3Obj={s3Obj}
                  />
                  <span>{name.toLowerCase()}</span>
                </div> */}

                {columns.map((column, index) => {
                  return (
                    <div
                      className={`w-full flex items-center text-xs ${
                        leftAlign ? 'text-left' : 'text-center'
                      }
                `}
                      key={index}
                      style={column.style}
                    >
                      {column.children
                        ? column.children(row)
                        : row[column.accessorKey] ?? '-'}
                    </div>
                  )
                })}
              </div>
            )
          })}
          {pageLoading && (
            <div className="grid place-content-center">
              <Loader
                loading={pageLoading}
                caption={'Loading More Data'}
                flex
              />
            </div>
          )}
        </div>
      ) : (
        <div className="grid place-content-center " style={{ height: '50vh' }}>
          No Data found
        </div>
      )}
    </div>
  )
}

export default HeadlessTable
