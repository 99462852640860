import React, { useState, useCallback, useEffect } from 'react'
import {
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  Tab,
  Tabs,
  Grid,
  IconButton,
  Box,
  Tooltip
} from '@mui/material'
import { useDropzone } from 'react-dropzone'
import { Button } from '../../components'
import { v4 as uuid } from 'uuid'
import { getSignedUrl } from '../../utils/AWS'
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate'

function checkImageTypeBase64(imageString) {
  const isBase64 = imageString.startsWith('data:image')
  if (isBase64) {
    return true
  }
  return false
}

const ProfilePicker = (props) => {
  const {
    name = '',
    profile_pic = '',
    style = {},
    isEditable = false,
    handleMode = () => {},
    onImageSelect = () => {},
    isReadOnly = false,
    viewImage = () => {},
    faces = {},
    s3Obj = {},
    currentPage = 1,
    isMasterPicker = false
  } = props

  const [open, setOpen] = useState(false)
  const [tabValue, setTabValue] = useState(0)
  const [facePage, setFacePage] = useState('')
  const [profilePic, setProfilePic] = useState(null)

  useEffect(() => {
    const initalizeData = async () => {
      if (profile_pic && profile_pic.src) {
        const url = checkImageTypeBase64(profile_pic.src)
          ? profile_pic.src
          : await getSignedUrl(profile_pic.src, s3Obj)
        setProfilePic({
          ...profile_pic,
          src: url
        })
      } else {
        setProfilePic(null)
      }
    }
    setProfilePic(null)
    initalizeData()
  }, [profile_pic])

  useEffect(() => {
    if (currentPage && !isMasterPicker) {
      if (Object.keys(faces).includes(currentPage.toString())) {
        setFacePage(currentPage)
      }
    }
  }, [currentPage])

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0]
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        const base64String = reader.result?.toString().split(',')[1]
        if (base64String) {
          const prefix = `data:${file.type};base64,`
          const src = prefix + base64String
          onImageSelect({
            src,
            name: uuid() + '.' + file.name.split('.').pop()
          })
          setOpen(false)
        }
      }
      reader.readAsDataURL(file)
    }
  }, [])

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/*'
  })

  const handleScreenShot = () => {
    setOpen(false)
    handleMode('screenshot_profile', true)
  }

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        cursor: isEditable ? 'pointer' : '',
        '&:hover img': {
          opacity: isEditable ? '0.5' : '1'
        },
        '&:hover': {
          '& div': {
            display: 'flex !important',
            opacity: '1 !important'
          }
        },
        '&:hover button': {
          opacity: isEditable ? '0.5' : '1'
        }
      }}
    >
      <Tooltip title={'Add project image'}>
        <IconButton disableRipple onClick={() => setOpen(true)}>
          <AddPhotoAlternateIcon />
        </IconButton>
      </Tooltip>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Choose an image</DialogTitle>
        <DialogContent>
          {
            <Tabs
              value={tabValue}
              onChange={(e, newValue) => setTabValue(newValue)}
            >
              <Tab label="Screenshot PDF" />
              <Tab label="Upload" />
            </Tabs>
          }
          {tabValue === 0 && (
            <Box
              sx={{
                padding: '21px',
                marginTop: '20px',
                height: '150px',
                width: '450px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Button onClick={() => handleScreenShot()}>
                Take a Screenshot
              </Button>
            </Box>
          )}
          {tabValue === 1 && (
            <Box
              {...getRootProps()}
              style={{
                border: '1px dashed gray',
                padding: 20,
                marginTop: 20,
                cursor: 'pointer',
                height: '150px',
                width: '450px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <input {...getInputProps()} />
              <p>Drag & drop an image here, or click to select one</p>
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </Box>
  )
}

export default ProfilePicker
