import React, { useEffect, useRef, useState } from 'react'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import { EnterIcon, SearchIcon } from '../Icons/Icons'
import { useStyles } from '../CommandBar/styles'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../config/routes'
import { Fade } from '@mui/material'
import trackEvent from '../../utils/TrackEvent/TrackEvent'
import mixpanelEvents from '../../config/mixpanelEvents'

function CommandBar({ open, setOpen, perform }) {
  const styles = useStyles()
  const navigate = useNavigate()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const actions = [
    {
      title: 'Knowledge',
      perform: () => {
        navigate(ROUTES.LIBRARY_KNOWLEDGE)
        trackEvent(
          mixpanelEvents.COMMANDBAR_NAVIGATION,
          'NAVIGATION',
          {},
          {
            route: 'Knowledge'
          }
        )

        setOpen(false)
      }
    },
    // {
    //   title: 'Create new knowledge',
    //   perform: () => {
    //     navigate(ROUTES.LIBRARY_KNOWLEDGE)
    //     const timeinterval = setInterval(() => {
    //       const ele = document.getElementById('knowledge-create-button')
    //       if (ele) {
    //         ele.click()
    //         setOpen(false)
    //         clearInterval(timeinterval)
    //       }
    //     }, 500)
    //   }
    // },
    {
      title: 'Chat',
      perform: () => {
        navigate(ROUTES.CHAT)
        trackEvent(
          mixpanelEvents.COMMANDBAR_NAVIGATION,
          'NAVIGATION',
          {},
          {
            route: 'Chat'
          }
        )
        setOpen(false)
      }
    },
    {
      title: 'Workspaces',
      perform: () => {
        navigate(ROUTES.WORKSPACE)
        trackEvent(
          mixpanelEvents.COMMANDBAR_NAVIGATION,
          'NAVIGATION',
          {},
          {
            route: 'Workspaces'
          }
        )
        setOpen(false)
      }
    },
    {
      title: 'People',
      perform: () => {
        navigate(ROUTES.LIBRARY_PEOPLE_RESUME)
        trackEvent(
          mixpanelEvents.COMMANDBAR_NAVIGATION,
          'NAVIGATION',
          {},
          {
            route: 'People'
          }
        )
        setOpen(false)
      }
    },
    {
      title: 'Files',
      perform: () => {
        navigate(ROUTES.FILES)
        trackEvent(
          mixpanelEvents.COMMANDBAR_NAVIGATION,
          'NAVIGATION',
          {},
          {
            route: 'Files'
          }
        )
        setOpen(false)
      }
    },
    {
      title: 'Settings',
      perform: () => {
        navigate(ROUTES.SETTINGS_PROFILE)
        trackEvent(
          mixpanelEvents.COMMANDBAR_NAVIGATION,
          'NAVIGATION',
          {},
          {
            route: 'Settings'
          }
        )
        setOpen(false)
      }
    }
  ]

  const selected = useRef(0)

  const [selectedCommand, setSelectedCommand] = useState(0)
  const [search, setSearch] = useState('')
  const [commands, setCommands] = useState(actions)
  const commandsRef = useRef(commands)
  const inputRef = useRef(null)

  useEffect(() => {
    if (open) {
      trackEvent(mixpanelEvents.COMMAND_BAR_LAUNCHED, 'OPEN', {}, {})
    }
  }, [open])
  useEffect(() => {
    document.addEventListener('keydown', (e) => {
      if (commandsRef.current.length === 0) {
        if (e.key === 'Enter') {
          perform(() => {
            if (inputRef?.current?.value) {
              trackEvent(
                mixpanelEvents.COMMAND_BAR_COMMAND_SEARCH,
                'SEARCH',
                {},
                {}
              )
              navigate(`/dashboard/result?keyword="${inputRef.current.value}"`)
              setOpen(false)
            }
          })
        }
      }
    })

    return () => {
      if (inputRef.current) {
        inputRef.current.value = ''
      }
    }
  }, [])

  const handleClose = () => {
    setSearch('')

    setOpen(false)
  }

  useEffect(() => {
    inputRef?.current?.focus()
  }, [open])

  const handleCommand = (command) => {
    command.perform()
  }

  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Fade}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '5px 10px',
            borderBottom: '1px solid #e0e0e0',
            minWidth: '500px'
          }}
        >
          <SearchIcon
            height={18}
            width={18}
            style={{
              color: 'var(--primary-joist)'
            }}
          />
          <input
            placeholder="Search or run a command"
            autoFocus
            ref={inputRef}
            onChange={(e) => {
              setSearch(e.target.value)
              const filterd = actions.filter((action) => {
                return action.title
                  .toLowerCase()
                  .includes(e.target.value.toLowerCase())
              })
              setCommands(filterd)
              commandsRef.current = filterd
            }}
            style={{
              border: 'none',
              outline: 'none',
              padding: '8px',
              fontSize: '14px',
              width: '100%'
            }}
          />

          <div className={styles.escBtn}>esc</div>
        </div>
        <DialogContent
          style={{
            padding: '0',
            minHeight: '300px'
          }}
        >
          {commands.length === 0 && (
            <div
              onClick={() => {
                handleCommand({
                  perform: () => {
                    navigate(`/dashboard/result?keyword="${search}"`)
                    setOpen(false)
                  }
                })
              }}
              className={styles.commandItemActive}
            >
              Search for "{search}"
            </div>
          )}
          {commands.map((command, index) => {
            return (
              <div
                onClick={() => handleCommand(command)}
                key={index}
                className={styles.commandItem}
                onMouseOver={() => {
                  setSelectedCommand(index)
                }}
                onMouseLeave={() => {
                  setSelectedCommand(-1)
                }}
              >
                <span>{command.title}</span>
                <span
                  style={{
                    padding: '5px',
                    borderRadius: '5px',
                    color: 'var(--primary-joist)',
                    display: 'grid',
                    placeContent: 'center',
                    visibility: selectedCommand === index ? 'visible' : 'hidden'
                  }}
                >
                  <EnterIcon
                    style={{
                      height: '16px',
                      width: '16px'
                    }}
                  />
                </span>
              </div>
            )
          })}
        </DialogContent>
      </Dialog>
    </React.Fragment>
  )
}

export default CommandBar
